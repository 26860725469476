import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexDigitWithDecimal = '^[0-9]{0,7}(.[0-9]{0,2})?$'
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 7 digits and  2 decimal'

  return Yup.object({
    additionDeductionCode: Yup.string().nullable(),
    amount: Yup.string()
      .nullable()
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal)
      .max(10, 'Max length allowed is 10'),
    additionDeductionInd: Yup.string().nullable()
  })
}

export default makeValidationSchema
