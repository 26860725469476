import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../lookups/USInvoiceLookupsContext'
import CngField from '../../../../components/cngcomponents/CngField'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

function NaftaFormViewContent(props) {
  const { naftaForm } = props

  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const blanketPeriodFrom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.BLANKET_PERIOD_FROM
    )
    const blanketPeriodTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.BLANKET_PERIOD_TO
    )
    const cerifierType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CERIFIER_TYPE
    )
    const naftapartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.N_A_F_T_APARTY_NAME
    )
    const company = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.COMPANY
    )
    const contactTelNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CONTACT_TEL_NUM
    )
    const contactFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CONTACT_FAX
    )
    const email = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.EMAIL
    )
    const naftaDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.NAFTA_DATE
    )

    return {
      blanketPeriodFrom,
      blanketPeriodTo,
      cerifierType,
      naftapartyName,
      company,
      contactTelNum,
      contactFax,
      email,
      naftaDate
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.blanketPeriodFrom}>
          {naftaForm.blanketPeriodFrom &&
            moment(naftaForm.blanketPeriodFrom).format('D MMM YYYY')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.blanketPeriodTo}>
          {naftaForm.blanketPeriodTo &&
            moment(naftaForm.blanketPeriodTo).format('D MMM YYYY')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.cerifierType}>
          {getLookupValue('cerifierType', naftaForm.cerifierType)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.naftapartyName}>
          {naftaForm.naftapartyName}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.company}>
          {naftaForm.company}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label='Title'>
          {naftaForm.title}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.contactTelNum}>
          {naftaForm.contactTelNum}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.contactFax}>
          {naftaForm.contactFax}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.email}>
          {naftaForm.email}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.naftaDate}>
          {naftaForm.naftaDate &&
            moment(naftaForm.naftaDate).format('D MMM YYYY')}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default NaftaFormViewContent
