import makeValidationSchema from './FdabtaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core'
import Switch from '../../../../../components/Switch'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  usCustomBroker: '',
  otherRefNo: '',
  priorNoticeNo: '',
  billCustomChargsSh: '',
  billCustomChargsCn: '',
  billCustomChargsOt: '',
  vesselName: '',
  specifyOther:'',
  dateOfExport: '',
  flightNo: '',
  tripNo: '',
  frieghtIncluded: '',
  exportPermitNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()

  const billCustomChargsSh = watch('billCustomChargsSh')
  const billCustomChargsCn = watch('billCustomChargsCn')
  const billCustomChargsOt = watch('billCustomChargsOt')

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let fdabtaform = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.TITLE
    )
    let invoiceId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.INVOICE_ID
    )
    let usCustomBroker = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.US_CUSTOM_BROKER
    )
    let otherRefNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.OTHER_REF_NO
    )
    let priorNoticeNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.PRIOR_NOTICE_NO
    )
    let billCustomChargsSh = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_SH
    )
    let billCustomChargsCn = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_CN
    )
    let billCustomChargsOt = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_OT
    )
    let vesselName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.VESSEL_NAME
    )
    let dateOfExport = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.DATE_OF_EXPORT
    )
    let specifyOther = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.SPECIFY_OTHERS
    )

    let flightNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.FLIGHT_NO
    )
    let tripNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.TRIP_NO
    )
    let frieghtIncluded = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.FRIEGHT_INCLUDED
    )
    let exportPermitNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.EXPORT_PERMIT_NUMBER
    )

    return {
      fdabtaform,
      invoiceId,
      usCustomBroker,
      otherRefNo,
      priorNoticeNo,
      billCustomChargsSh,
      billCustomChargsCn,
      billCustomChargsOt,
      vesselName,
      dateOfExport,
      specifyOther,
      flightNo,
      tripNo,
      frieghtIncluded,
      exportPermitNumber
    }
  }

  function handleChangeBillCustomChargesTo(type, value) {
    if (!value) {
      setValue('billCustomChargsSh', '', { shouldDirty: true })
      setValue('billCustomChargsCn', '', { shouldDirty: true })
      setValue('billCustomChargsOt', '', { shouldDirty: true })
    } else {
      switch (type) {
        case 'SH': {
          setValue('billCustomChargsSh', 'Y', { shouldDirty: true })
          setValue('billCustomChargsCn', '', { shouldDirty: true })
          setValue('billCustomChargsOt', '', { shouldDirty: true })
          return
        }

        case 'CN': {
          setValue('billCustomChargsSh', '', { shouldDirty: true })
          setValue('billCustomChargsCn', 'Y', { shouldDirty: true })
          setValue('billCustomChargsOt', '', { shouldDirty: true })
          return
        }

        case 'OT': {
          setValue('billCustomChargsSh', '', { shouldDirty: true })
          setValue('billCustomChargsCn', '', { shouldDirty: true })
          setValue('billCustomChargsOt', 'Y', { shouldDirty: true })
          return
        }

        default:
          return
      }
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.usCustomBroker}>
            <CngTextField
              name='usCustomBroker'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.usCustomBroker}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.otherRefNo}>
            <CngTextField
              name='otherRefNo'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.otherRefNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.priorNoticeNo}>
            <CngTextField
              name='priorNoticeNo'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.priorNoticeNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.vesselName}>
            <CngTextField
              name='vesselName'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.vesselName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.dateOfExport}>
            <CngDateField
              name='dateOfExport'
              label={translatedTextsObject.dateOfExport}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <Grid item xs={12}>
            <Card variant='outlined'>
              <Box padding={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm='auto'>
                    <Box py={1} pr={1}>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        style={{ fontWeight: 600 }}
                      >
                        Bill custom charges to
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={billCustomChargsSh === 'Y'}
                          onChange={(event) => handleChangeBillCustomChargesTo('SH', event.target.checked)}
                          color='primary'
                          size='small'
                        />
                      }
                      label={<Typography variant='body2'>Shipper</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={billCustomChargsCn === 'Y'}
                          onChange={(event) => handleChangeBillCustomChargesTo('CN', event.target.checked)}
                          color='primary'
                          size='small'
                        />
                      }
                      label={<Typography variant='body2'>Consignee</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={billCustomChargsOt === 'Y'}
                          onChange={(event) => handleChangeBillCustomChargesTo('OT', event.target.checked)}
                          color='primary'
                          size='small'
                        />
                      }
                      label={<Typography variant='body2'>Others</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <CngTextField shouldHide={shouldHideMap?.specifyOther}
                      name='specifyOther'
                      label={translatedTextsObject.specifyOther}
                      disabled={disabled || billCustomChargsOt !== 'Y'}
                      size='small'
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid alignItems='center' container spacing={2}>
            <Grid item xs='auto'>
              <Typography variant='body2' style={{ fontWeight: 600 }}>
                Exporting carrier information
              </Typography>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.flightNo}>
            <CngTextField
              name='flightNo'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.flightNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tripNo}>
            <CngTextField
              name='tripNo'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.tripNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.frieghtIncluded}>
            <Switch
              name='frieghtIncluded'
              label={translatedTextsObject.frieghtIncluded}
              disabled={disabled}
              fullWidth
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.exportPermitNumber}>
            <CngTextField
              inputProps={{ maxLength: 15 }}
              name='exportPermitNumber'
              label={translatedTextsObject.exportPermitNumber}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

const FDABTAFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FDABTAFormFormProperties
