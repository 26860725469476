import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import TscaDetailsMakeValidationSchema from './TscaDetailsMakeValidationSchema'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  const phonePattern = '^[0-9 -]*$'
  const phoneErrorMessage =
    'Please enter valid phone number[digits with space or -]'

  return Yup.object({
    tcsaname: Yup.string()
      .nullable()
      .max(50, 'Max value allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    tscaDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    title: Yup.string()
      .nullable()
      .max(50, 'Max value allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    phone: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(phonePattern, phoneErrorMessage),
    companyName: Yup.string()
      .nullable()
      .max(60, 'Max value allowed is 60')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    address1: Yup.string()
      .nullable()
      .max(200, 'Max value allowed is 200')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    address2: Yup.string()
      .nullable()
      .max(200, 'Max value allowed is 200')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    cityStateZip: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    choice1Or2: Yup.string().nullable(),
    choice3: Yup.string().nullable(),
    choice4: Yup.string().nullable(),
    email: Yup.string().nullable().max(50,"Max length allowed is 50").email("Invalid email").matches("[A-Za-z0-9+_.-]+@(.+)$", "Enter Email in valid pattern")
  })
}

export default makeValidationSchema
