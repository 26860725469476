import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import makeValidationSchema from './FDAFormMakeValidationSchema'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  productDesc: '',
  quantity: '',
  modelNo: '',
  brandName: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const productDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.PRODUCT_DESC
    )
    const quantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.QUANTITY
    )
    const modelNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.MODEL_NO
    )
    const brandName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.BRAND_NAME
    )

    return { productDesc, quantity, modelNo, brandName }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.productDesc}>
        <CngTextField
          name='productDesc'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.productDesc}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name='quantity'
          inputProps={{ maxLength: 6 }}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.modelNo}>
        <CngTextField
          name='modelNo'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.modelNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.brandName}>
        <CngTextField
          name='brandName'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.brandName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const FDAFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FDAFormFormProperties
