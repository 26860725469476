import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import makeValidationSchema from './ShipmentSummaryMakeValidationSchema'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  noOfPackages: '',
  packageType: '',
  packageDimL: '',
  packageDimW: '',
  packageDimH: '',
  dimensionsUOM: '',
  prodDesc: '',
  grossWeight: '',
  grossWeightUOM: '',
  freightClass: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const noOfPackages = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.NO_OF_PACKAGES
    )
    const packageType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_TYPE
    )
    const packageDimL = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_L
    )
    const packageDimW = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_W
    )
    const packageDimH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_H
    )
    const dimensionsUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.DIMENSIONS_U_O_M
    )
    const prodDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PROD_DESC
    )
    const grossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.GROSS_WEIGHT
    )
    const grossWeightUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.GROSS_WEIGHT_U_O_M
    )
    const freightClass = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.FREIGHT_CLASS
    )

    return {
      noOfPackages,
      packageType,
      packageDimL,
      packageDimW,
      packageDimH,
      dimensionsUOM,
      prodDesc,
      grossWeight,
      grossWeightUOM,
      freightClass
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={2} xl={3} shouldHide={shouldHideMap?.noOfPackages}>
        <CngTextField
          name='noOfPackages'
          label={translatedTextsObject.noOfPackages}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={2} xl={3} shouldHide={shouldHideMap?.packageType}>
        <CngCodeMasterAutocompleteField
          name='packageType'
          label={translatedTextsObject.packageType}
          disabled={disabled}
          size='small'
          codeType='US_INV_SUMMARY_PACKAGE_TYPE'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.packageDimL}>
        <CngTextField
          name='packageDimL'
          label={translatedTextsObject.packageDimL}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.packageDimW}>
        <CngTextField
          name='packageDimW'
          label={translatedTextsObject.packageDimW}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.packageDimH}>
        <CngTextField
          name='packageDimH'
          label={translatedTextsObject.packageDimH}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={2} xl={3} shouldHide={shouldHideMap?.dimensionsUOM}>
        <CngCodeMasterAutocompleteField
          name='dimensionsUOM'
          label={translatedTextsObject.dimensionsUOM}
          disabled={disabled}
          size='small'
          codeType='US_INV_SUMMARY_UOM'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} xl={6} shouldHide={shouldHideMap?.prodDesc}>
        <CngTextField
          name='prodDesc'
          label={translatedTextsObject.prodDesc}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={6} md={2} shouldHide={shouldHideMap?.grossWeight}>
        <CngTextField
          name='grossWeight'
          label={translatedTextsObject.grossWeight}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={6} md={2} shouldHide={shouldHideMap?.grossWeightUOM}>
        <CngCodeMasterAutocompleteField
          name='grossWeightUOM'
          label={translatedTextsObject.grossWeightUOM}
          disabled={disabled}
          size='small'
          codeType='US_INV_WEIGHT_UOM'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} xl={2} shouldHide={shouldHideMap?.freightClass}>
        <CngCodeMasterAutocompleteField
          name='freightClass'
          label={translatedTextsObject.freightClass}
          disabled={disabled}
          size='small'
          codeType='US_INV_SUMMARY_FREIGHT'
        />
      </CngGridItem>
    </Grid>
  )
}

const ShipmentSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ShipmentSummaryFormProperties
