import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../lookups/USInvoiceLookupsContext'
import CngField from '../../../../components/cngcomponents/CngField'
import _ from 'lodash'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'

const {
  button: { CngButton },
  CngDialog
} = components

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 8px 8px 16px',
    '& .MuiIconButton-root': {
      minHeight: 'auto'
    }
  },
  badge: {
    padding: 4,
    minWidth: 40,
    borderRadius: 32
  }
}))

function InvoiceSummarySection(props) {
  const { invoiceSummary } = props

  const [referenceDialog, setReferenceDialog] = useState({
    open: false,
    references: []
  })
  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const classes = useStyles()
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceSummary = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceSummary.TITLE
    )
    const totalInvoice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceSummary.TOTAL_INVOICE
    )
    const summaryReference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.TITLE
    )
    const noOfPackages = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.NO_OF_PACKAGES
    )
    const packageType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_TYPE
    )
    const packageDimL = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_L
    )
    const packageDimW = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_W
    )
    const packageDimH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PACKAGE_DIM_H
    )
    const dimensionsUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.DIMENSIONS_U_O_M
    )
    const prodDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.PROD_DESC
    )
    const grossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.GROSS_WEIGHT
    )
    const grossWeightUOM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.GROSS_WEIGHT_U_O_M
    )
    const freightClass = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NbciUSInvoiceShipmentSummary.FREIGHT_CLASS
    )
    const additionorDeductionCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.ADD_DED_CODE
    )
    const refAmount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.REF_AMOUNT
    )

    return {
      invoiceSummary,
      totalInvoice,
      summaryReference,
      noOfPackages,
      packageType,
      packageDimL,
      packageDimW,
      packageDimH,
      dimensionsUOM,
      prodDesc,
      grossWeight,
      grossWeightUOM,
      freightClass,
      additionorDeductionCode,
      refAmount,
    }
  }

  return (
    <>
      <Card variant='outlined'>
        <CardHeader
          title={translatedTextsObject.invoiceSummary}
          titleTypographyProps={{ style: { fontWeight: 600 } }}
        />
        <Box padding={2}>
          {!_.isEmpty(invoiceSummary) ? (
            <Grid container spacing={2}>
              {invoiceSummary.map((summary) => {
                const { nbciUSInvoiceShipmentSummary, nbciusinvoiceSummaryReference } = summary

                const hasShipmentSummary = !_.isEmpty(nbciUSInvoiceShipmentSummary)
                const hasSummaryReference = !_.isEmpty(nbciusinvoiceSummaryReference)

                return (
                  <Grid key={summary.id} item xs={12}>
                    <Card variant='outlined'>
                      <Box
                        bgcolor={theme.palette.background?.sectionOddBg || theme.palette.grey[200]}
                        padding={2}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Card variant='outlined'>
                              <Box padding={2}>
                                {hasShipmentSummary ? (
                                  <Grid container spacing={2}>
                                    {nbciUSInvoiceShipmentSummary.map(
                                      (shipment, index, shipmentSummary) => (
                                        <React.Fragment key={shipment.id}>
                                          <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                              <Grid item xs={12} sm={6} md={2} xl={3}>
                                                <CngField label={translatedTextsObject.noOfPackages}>
                                                  {shipment.noOfPackages}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} sm={6} md={2} xl={3}>
                                                <CngField label={translatedTextsObject.packageType}>
                                                  {getLookupValue('packageType', shipment.packageType)}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} sm={4} md={2} xl={1}>
                                                <CngField label={translatedTextsObject.packageDimL}>
                                                  {shipment.packageDimL}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} sm={4} md={2} xl={1}>
                                                <CngField label={translatedTextsObject.packageDimW}>
                                                  {shipment.packageDimW}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} sm={4} md={2} xl={1}>
                                                <CngField label={translatedTextsObject.packageDimH}>
                                                  {shipment.packageDimH}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} md={2} xl={3}>
                                                <CngField label={translatedTextsObject.dimensionsUOM}>
                                                  {getLookupValue('dimensionsUOM', shipment.dimensionsUOM)}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} md={4} xl={6}>
                                                <CngField label={translatedTextsObject.prodDesc}>
                                                  {shipment.prodDesc}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={6} md={2}>
                                                <CngField label={translatedTextsObject.grossWeight}>
                                                  {shipment.grossWeight}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={6} md={2}>
                                                <CngField label={translatedTextsObject.grossWeightUOM}>
                                                  {getLookupValue('weightUom', shipment.grossWeightUOM)}
                                                </CngField>
                                              </Grid>
                                              <Grid item xs={12} md={4} xl={2}>
                                                <CngField label={translatedTextsObject.freightClass}>
                                                  {getLookupValue('freightClass', shipment.freightClass)}
                                                </CngField>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          {index + 1 < shipmentSummary.length && (
                                            <Grid item xs={12}>
                                              <Divider />
                                            </Grid>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </Grid>
                                ) : (
                                  <Typography variant='body2' color='textSecondary'>
                                    No shipment summary to display
                                  </Typography>
                                )}
                              </Box>
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.totalInvoice}>
                                  {summary.totalInvoice}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Card
                                  variant='outlined'
                                  onClick={() => {
                                    if (!hasSummaryReference) return

                                    setReferenceDialog({
                                      open: true,
                                      references: nbciusinvoiceSummaryReference
                                    })
                                  }}
                                  style={{ cursor: hasSummaryReference ? 'pointer' : 'default' }}
                                >
                                  <Box className={classes.wrapper}>
                                    <Box flexGrow='1'>
                                      <Typography variant='body2' style={{ fontWeight: 600 }}>
                                        {translatedTextsObject.summaryReference}
                                      </Typography>
                                    </Box>
                                    <div>
                                      <Paper className={classes.badge} variant='outlined'>
                                        <Typography
                                          align='center'
                                          variant='body2'
                                          style={{ fontWeight: 600 }}
                                        >
                                          {nbciusinvoiceSummaryReference.length}
                                        </Typography>
                                      </Paper>
                                    </div>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </Box>
      </Card>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <Grid container spacing={2}>
              {referenceDialog.references.map((reference, index) => (
                <React.Fragment key={reference.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.additionorDeductionCode}>
                          {getLookupValue('additionDeductionCode', reference.additionorDeductionCode)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CngField label={translatedTextsObject.refAmount}>
                          {reference.refAmount}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index + 1 < referenceDialog.references.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setReferenceDialog({ open: false, references: [] })}>
            OK
          </CngButton>
        }
        dialogTitle={translatedTextsObject.summaryReference}
        fullWidth
        maxWidth='sm'
        onClose={() => setReferenceDialog({ open: false, references: [] })}
        open={referenceDialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default InvoiceSummarySection
