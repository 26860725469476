import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../lookups/USInvoiceLookupsContext'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CngField from '../../../../components/cngcomponents/CngField'
import { Divider, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

function LaceyActFormViewContent(props) {
  const { laceyAct } = props

  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.CONTAINER_NO
    )
    const printName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.PRINT_NAME
    )
    const descOfMerchandise = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.DESC_OF_MERCHANDISE
    )
    const laceyDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.LACEY_DATE
    )
    const estimatedArrivalDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.LaceyAct.ESTIMATED_ARRIVAL_DATE
    )
    const laceyActDetailsTitle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.TITLE
    )
    const htsNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.HTS_NO
    )
    const enteredValue = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.ENTERED_VALUE
    )
    const componentOfArticle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.COMPONENT_OF_ARTICLE
    )
    const genusGame = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.GENUS_GAME
    )
    const speciesName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.SPECIES_NAME
    )
    const country = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.COUNTRY
    )
    const quantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.QUANTITY
    )
    const uom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.UOM
    )
    const percentOfRecycle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.PERCENT_OF_RECYCLE
    )

    return {
      containerNo,
      printName,
      descOfMerchandise,
      laceyDate,
      estimatedArrivalDate,
      laceyActDetailsTitle,
      htsNo,
      enteredValue,
      componentOfArticle,
      genusGame,
      speciesName,
      country,
      quantity,
      uom,
      percentOfRecycle,
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.containerNo}>
              {laceyAct.containerNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.laceyDate}>
              {laceyAct.laceyDate &&
                moment(laceyAct.laceyDate).format('D MMM YYYY')}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.printName}>
              {laceyAct.printName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.estimatedArrivalDate}>
              {laceyAct.estimatedArrivalDate &&
                moment(laceyAct.estimatedArrivalDate).format('D MMM YYYY')}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <CngField label={translatedTextsObject.descOfMerchandise}>
              {laceyAct.descOfMerchandise}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.laceyActDetailsTitle}>
          {!_.isEmpty(laceyAct.nbciusinvoiceLaceyActDetails) ? (
            <Grid container spacing={2}>
              {laceyAct.nbciusinvoiceLaceyActDetails.map(
                (detail, index, laceyActDetails) => (
                  <React.Fragment key={detail.id}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.htsNo}>
                          {detail.htsNo}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.enteredValue}>
                          {detail.enteredValue}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.componentOfArticle}>
                          {detail.componentOfArticle}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.genusGame}>
                          {detail.genusGame}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.speciesName}>
                          {detail.speciesName}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.country}>
                          {getLookupValue('country', detail.country)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.quantity}>
                          {detail.quantity}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.uom}>
                          {detail.uom}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.percentOfRecycle}>
                          {detail.percentOfRecycle}
                        </CngField>
                      </Grid>
                    </Grid>
                    {index + 1 < laceyActDetails.length && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default LaceyActFormViewContent
