import makeValidationSchema from './USAPHISFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../../../../components/na'
import NaUSPortAutocompleteField from '../../../../../components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import USAPHISAnimalSection from './USAPHISAnimalSection'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngTextField,
      CngCountryAutocompleteField,
      CngDateField,
      CngSelectField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  portOfEmbarkation: '',
  countryOfHealthCare: '',
  shippingDate: '',
  arrivalDate: '',
  usaphisName: '',
  usaphisAddress: '',
  usaphisCity: '',
  usaphisStateCode: '',
  usaphisCountryCode: '',
  usaphisPostalCode: '',
  usaphisTelNo: '',
  deliveryInUS: '',
  importPermitNumbers: '',
  usaphisRemarks: '',
  usaphisPrintName: '',
  usaphisTitle: '',
  vetDate: '',
  usaphiportOfEntry: '',
  nbciusinvoiceUSAPHISAnimalFormDTO: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()

  const health = watch('countryOfHealthCare')
  const portOfEntry = watch('usaphiportOfEntry')
  const country = watch('usaphisCountryCode')
  const state = watch('usaphisStateCode')
  useEffect(() => {
    setValue('countryOfHealthCare', health, { shouldDirty: true })
  }, [health])
  useEffect(() => {
    setValue('usaphiportOfEntry', portOfEntry, { shouldDirty: true })
  }, [portOfEntry])
  useEffect(() => {
    setValue('usaphisCountryCode', country, { shouldDirty: true })
  }, [country])
  useEffect(() => {
    setValue('usaphisStateCode', state, { shouldDirty: true })
  }, [state])

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portOfEmbarkation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.PORT_OF_EMBARKATION
    )
    const countryOfHealthCare = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.COUNTRY_OF_HEALTH_CARE
    )
    const shippingDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.SHIPPING_DATE
    )
    const arrivalDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.ARRIVAL_DATE
    )
    const usaphisName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_NAME
    )
    const usaphisAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_ADDRESS
    )
    const usaphisCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_CITY
    )
    const usaphisStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_STATE_CODE
    )
    const usaphisCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_COUNTRY_CODE
    )
    const usaphisPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_POSTAL_CODE
    )
    const usaphisTelNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_TEL_NO
    )
    const deliveryInUS = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.DELIVERY_IN_U_S
    )
    const importPermitNumbers = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.IMPORT_PERMIT_NUMBERS
    )
    const usaphisRemarks = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_REMARKS
    )
    const usaphisPrintName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_PRINT_NAME
    )
    const usaphisTitle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_TITLE
    )
    const vetDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.VET_DATE
    )
    const usaphiportOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIPORT_OF_ENTRY
    )

    return {
      portOfEmbarkation,
      countryOfHealthCare,
      shippingDate,
      arrivalDate,
      usaphisName,
      usaphisAddress,
      usaphisCity,
      usaphisStateCode,
      usaphisCountryCode,
      usaphisPostalCode,
      usaphisTelNo,
      deliveryInUS,
      importPermitNumbers,
      usaphisRemarks,
      usaphisPrintName,
      usaphisTitle,
      vetDate,
      usaphiportOfEntry
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usaphisTitle}>
            <CngSelectField
              name='usaphisTitle'
              label={translatedTextsObject.usaphisTitle}
              disabled={disabled}
              items={[
                { text: 'Authorozed Agent', value: 'AUTHORIZEDAGENT' },
                { text: 'Owner', value: 'OWNER' },
                { text: 'Agent', value: 'IMPORTER' }
              ]}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={6} shouldHide={shouldHideMap?.usaphisName}>
            <CngTextField
              name='usaphisName'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.usaphisName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vetDate}>
            <CngDateField
              name='vetDate'
              label={translatedTextsObject.vetDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.portOfEmbarkation}>
            <CngTextField
              name='portOfEmbarkation'
              inputProps={{ maxLength: 80 }}
              label={translatedTextsObject.portOfEmbarkation}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.countryOfHealthCare}>
            <CngCountryAutocompleteField
              name='countryOfHealthCare'
              label={translatedTextsObject.countryOfHealthCare}
              disabled={disabled}
              size='small'
			        lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.shippingDate}>
            <CngDateField
              name='shippingDate'
              label={translatedTextsObject.shippingDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.arrivalDate}>
            <CngDateField
              name='arrivalDate'
              label={translatedTextsObject.arrivalDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} shouldHide={shouldHideMap?.usaphiportOfEntry}>
            <NaUSPortAutocompleteField
              name='usaphiportOfEntry'
              label={translatedTextsObject.usaphiportOfEntry}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={2}>
          <Grid alignItems='center' container spacing={2}>
            <Grid item xs='auto'>
              <Typography variant='body2' style={{ fontWeight: 600 }}>
                Name and mailing address
              </Typography>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.usaphisPrintName}>
            <CngTextField
              name='usaphisPrintName'
              inputProps={{ maxLength: 60 }}
              label={translatedTextsObject.usaphisPrintName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.usaphisAddress}>
            <CngTextField
              name='usaphisAddress'
              inputProps={{ maxLength: 110 }}
              label={translatedTextsObject.usaphisAddress}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usaphisCity}>
            <CngTextField
              name='usaphisCity'
              inputProps={{ maxLength: 30 }}
              label={translatedTextsObject.usaphisCity}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usaphisCountryCode}>
            <CngCountryAutocompleteField
              name='usaphisCountryCode'
              label={translatedTextsObject.usaphisCountryCode}
              disabled={disabled}
              size='small'
			  lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usaphisStateCode}>
            <NaStateCodeAutoCompleteField
              name='usaphisStateCode'
              label={translatedTextsObject.usaphisStateCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.usaphisPostalCode}>
            <CngTextField
              name='usaphisPostalCode'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.usaphisPostalCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.usaphisTelNo}>
            <CngTextField
              name='usaphisTelNo'
              inputProps={{ maxLength: 30 }}
              label={translatedTextsObject.usaphisTelNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.deliveryInUS}>
            <CngTextField
              name='deliveryInUS'
              inputProps={{ maxLength: 15 }}
              label={translatedTextsObject.deliveryInUS}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.importPermitNumbers}>
            <CngTextField
              name='importPermitNumbers'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.importPermitNumbers}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.usaphisRemarks}>
            <CngTextField
              name='usaphisRemarks'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.usaphisRemarks}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <USAPHISAnimalSection />
      </Grid>
    </Grid>
  )
}

const UsAPHISFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UsAPHISFormFormProperties
