import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    invoiceId: Yup.string().nullable(),
    otherRefNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    responsibleForCustoms: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    instructionsToBroker: Yup.string().nullable(),
    containerized: Yup.string().nullable(),
    billChargesTo: Yup.string().nullable(),
    portOfLoading: Yup.string().nullable(),
    flightDetails: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    placeOfDelivery: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    specialInstructions: Yup.string()
      .nullable()
      .max(200, 'Max length allowed is 200')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
