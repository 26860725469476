import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Grid } from '@material-ui/core'
import EPA35201FormSection from './EPA35201FormSection'
import EPA352021FormSection from './EPA352021FormSection'
import FCCFormSection from './FCCFormSection'
import FDAFormSection from './FDAFormSection'
import DOTFormSection from './DOTFormSection'
import { useFormContext } from 'react-hook-form'

function DeclarationsSection() {
  const { setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const epa35201 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.TITLE
    )
    const epa352021 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.TITLE
    )
    const fcc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.TITLE
    )
    const fda = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.TITLE
    )
    const dot = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.TITLE
    )

    return { epa35201, epa352021, fcc, fda, dot }
  }

  function handleSetDeclaration(name, declaration) {
    setValue(name, declaration)
  }

  return (
    <CngSection title='Declarations'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EPA35201FormSection
            title={translatedTextsObject.epa35201}
            onSetDeclaration={handleSetDeclaration}
          />
        </Grid>
        <Grid item xs={12}>
          <EPA352021FormSection
            title={translatedTextsObject.epa352021}
            onSetDeclaration={handleSetDeclaration}
          />
        </Grid>
        <Grid item xs={12}>
          <FCCFormSection
            title={translatedTextsObject.fcc}
            onSetDeclaration={handleSetDeclaration}
          />
        </Grid>
        <Grid item xs={12}>
          <FDAFormSection
            title={translatedTextsObject.fda}
            onSetDeclaration={handleSetDeclaration}
          />
        </Grid>
        <Grid item xs={12}>
          <DOTFormSection
            title={translatedTextsObject.dot}
            onSetDeclaration={handleSetDeclaration}
          />
        </Grid>
      </Grid>
    </CngSection>
  )
}

export default DeclarationsSection
