import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import CngField from '../../../../../components/cngcomponents/CngField'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function EPA35201Content(props) {
  const { epa35201, translate } = props

  const theme = useTheme()
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.PORT_CODE
    )
    const entryDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.ENTRY_DATE
    )
    const entryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.ENTRY_NO
    )
    const vinNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VIN_NUMBER
    )
    const manufactureDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MANUFACTURE_DATE
    )
    const manufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MANUFACTURER
    )
    const model = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MODEL
    )
    const modelYear = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MODEL_YEAR
    )
    const expiryDateOfCert = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.EXPIRY_DATE_OF_CERT
    )
    const dateForForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.DATE_FOR_FORM
    )
    const nameCompanyPhone = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.NAME_COMPANY_PHONE
    )
    const vehicleTypeB = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_B
    )
    const vehicleTypeF = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_F
    )
    const vehicleTypeEe = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_EE
    )
    const vehicleTypeFf = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_FF
    )
    const vehicleTypeM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_M
    )
    const vehicleTypeE = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_E
    )
    const vehicleTypeL = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_L
    )
    const vehicleTypeU = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_U
    )
    const vehicleTypeW = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_W
    )
    const vehicleTypeY = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Y
    )
    const vehicleTypeG = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_G
    )
    const vehicleTypeI = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_I
    )
    const vehicleTypeK = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_K
    )
    const vehicleTypeN = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_N
    )
    const vehicleTypeO = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_O
    )
    const vehicleTypeA = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_A
    )
    const vehicleTypeC = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_C
    )
    const vehicleTypeJ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_J
    )
    const vehicleTypeZ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Z
    )
    const vehicleTypeH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_H
    )
    const vehicleTypeQ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Q
    )

    return {
      portCode,
      entryDate,
      entryNo,
      vinNumber,
      manufactureDate,
      manufacturer,
      model,
      modelYear,
      expiryDateOfCert,
      dateForForm,
      nameCompanyPhone,
      vehicleTypeB,
      vehicleTypeF,
      vehicleTypeEe,
      vehicleTypeFf,
      vehicleTypeM,
      vehicleTypeE,
      vehicleTypeL,
      vehicleTypeU,
      vehicleTypeW,
      vehicleTypeY,
      vehicleTypeG,
      vehicleTypeI,
      vehicleTypeK,
      vehicleTypeN,
      vehicleTypeO,
      vehicleTypeA,
      vehicleTypeC,
      vehicleTypeJ,
      vehicleTypeZ,
      vehicleTypeH,
      vehicleTypeQ
    }
  }

  function renderVehicleCodeField(name) {
    function getColor() {
      return epa35201[name] === 'Y'
        ? theme.palette.primary.main
        : theme.palette.error.main
    }

    function getIcon() {
      return epa35201[name] === 'Y' ? 'check' : 'ban'
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <FontAwesomeIcon
            color={getColor()}
            fixedWidth
            icon={['fal', getIcon()]}
          />
        </Grid>
        <Grid item xs>
          <Typography variant='body2'>
            {translatedTextsObject[name]}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Description and Declaration of Motor Vehicle or Motor Vehicle
              Engine (Note: Heavy-duty Engines must use form 3520-21)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portCode}>
                  {epa35201.portCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryDate}>
                  {epa35201.entryDate}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryNo}>
                  {epa35201.entryNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.vinNumber}>
                  {epa35201.vinNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.manufactureDate}>
                  {epa35201.manufactureDate}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.manufacturer}>
                  {epa35201.manufacturer}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.model}>
                  {epa35201.model}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.modelYear}>
                  {epa35201.modelYear}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.expiryDateOfCert}>
                  {epa35201.expiryDateOfCert}
                </CngField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Names, Addresses, and Telephone Numbers of Relevant Parties
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <strong>Certification:</strong>&nbsp;I certify that I have read
              and understand the purpose of this form, the penalties for
              falsely declaring information, or for providing misleading
              information, or for concealing a material fact. The information
              I have provided is correct, and all required attachments are
              appended to this form. I authorise EPA Enforcement Officers to
              conduct inspections or testing permitted by the Clean Air Act. I
              am the owner, importer, or agent for the owner or importer.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.dateForForm}>
                  {epa35201.dateForForm}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={9}>
                <CngField label={translatedTextsObject.nameCompanyPhone}>
                  {epa35201.nameCompanyPhone}
                </CngField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Vehicle code details'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    U.S. Conforming and "identical vehicle"
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeB')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeF')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeEe')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeFf')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    UPA exempted vehicles
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeM')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeE')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Excluded vehicles
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeL')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeU')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeW')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeY')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Temporary imports
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeG')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeI')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeK')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeN')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeO')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Independent commercial importer(ICI) imports
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeA')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeC')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeJ')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeZ')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    OEM imports
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeH')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderVehicleCodeField('vehicleTypeQ')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default EPA35201Content
