import makeValidationSchema from './ServiceMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  additionDeductionCode: '',
  amount: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const additionDeductionCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.ADDITION_DEDUCTION_CODE
    )
    const amount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.AMOUNT
    )

    return { additionDeductionCode, amount }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.additionDeductionCode}>
        <CngCodeMasterAutocompleteField
          name='additionDeductionCode'
          label={translatedTextsObject.additionDeductionCode}
          disabled={disabled}
          codeType='US_INV_DETAIL_SERVICE_ADD_DED'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.amount}>
        <CngTextField
          name='amount'
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.amount}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceDetailsServiceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceDetailsServiceFormProperties
