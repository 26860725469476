import React from 'react'
import { components, constants } from 'cng-web-lib'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import FCCFormFormProperties from './FCCFormFormProperties'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Grid } from '@material-ui/core'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

const { initialValues, makeValidationSchema } =
  FCCFormFormProperties.formikProps

function FCCFormSection(props) {
  const { onSetDeclaration, title } = props

  const { watch } = useFormContext()
  const FCCForm = watch('nbciusinvoiceDetailsFCCForm')

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(FCCForm)} title={title}>
      {_.isEmpty(FCCForm) ? (
        <CngForm
          formikProps={{
            initialValues,
            makeValidationSchema,
            onSubmit: (data) =>
              onSetDeclaration('nbciusinvoiceDetailsFCCForm', [data])
          }}
          formState={FormState.COMPLETED}
          innerForm
          renderBodySection={(labelMap, shouldHideMap) => (
            <FCCFormFormProperties.Fields shouldHideMap={shouldHideMap} />
          )}
          renderButtonSection={() => (
            <Grid justify='flex-end' container spacing={2}>
              <Grid item xs='auto'>
                <CngButton size='medium' type='submit'>
                  Add declaration
                </CngButton>
              </Grid>
            </Grid>
          )}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonSection onSetDeclaration={onSetDeclaration} />
          </Grid>
          {FCCForm.map((_, index) => (
            <Grid key={index} item xs={12}>
              <FCCFormFormProperties.Fields index={index} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ButtonSection onSetDeclaration={onSetDeclaration} />
          </Grid>
        </Grid>
      )}
    </CollapsibleSection>
  )
}

function ButtonSection(props) {
  const { onSetDeclaration } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          size='small'
          onClick={() => onSetDeclaration('nbciusinvoiceDetailsFCCForm', [])}
        >
          Clear
        </CngButton>
      </Grid>
    </Grid>
  )
}

export default FCCFormSection
