import React from 'react'
import { useTranslation } from 'cng-web-lib'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import EPA35201Content from './EPA35201Content'
import EPA352021Content from './EPA352021Content'
import FCCContent from './FCCContent'
import FDAContent from './FDAContent'
import DOTContent from './DOTContent'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import _ from 'lodash'

function DeclarationsViewContent(props) {
  const { epa35201, epa352021, fcc, fda, dot } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const epa35201 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.TITLE
    )
    const epa352021 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.TITLE
    )
    const fcc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.TITLE
    )
    const fda = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.TITLE
    )
    const dot = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.TITLE
    )

    return { epa35201, fcc, epa352021, fda, dot }
  }

  function renderDeclaration(formKey) {
    function renderForm(form) {
      switch (formKey) {
        case 'epa35201':
          return <EPA35201Content epa35201={form} translate={translate} />
        case 'epa352021':
          return <EPA352021Content epa352021={form} translate={translate} />
        case 'fcc':
          return <FCCContent fcc={form} translate={translate} />
        case 'fda':
          return <FDAContent fda={form} translate={translate} />
        case 'dot':
          return <DOTContent dot={form} translate={translate} />
      }
    }

    return (
      <CngSection title={translatedTextsObject[formKey]}>
        <Grid container spacing={2}>
          {props[formKey].map((form) => (
            <Grid key={form.id} item xs={12}>
              <Card variant='outlined'>
                <Box padding={2}>{renderForm(form)}</Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CngSection>
    )
  }

  return (
    <CngSection title='Declarations'>
      {!_.isEmpty([...epa35201, ...epa352021, ...fcc, ...fda, ...dot]) ? (
        <Grid container spacing={2}>
          {!_.isEmpty(epa35201) && (
            <Grid item xs={12}>
              {renderDeclaration('epa35201')}
            </Grid>
          )}
          {!_.isEmpty(epa352021) && (
            <Grid item xs={12}>
              {renderDeclaration('epa352021')}
            </Grid>
          )}
          {!_.isEmpty(fcc) && (
            <Grid item xs={12}>
              {renderDeclaration('fcc')}
            </Grid>
          )}
          {!_.isEmpty(fda) && (
            <Grid item xs={12}>
              {renderDeclaration('fda')}
            </Grid>
          )}
          {!_.isEmpty(dot) && (
            <Grid item xs={12}>
              {renderDeclaration('dot')}
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography variant='body2' color='textSecondary'>
          No records to display
        </Typography>
      )}
    </CngSection>
  )
}

export default DeclarationsViewContent
