import React from 'react'
import { components, constants } from 'cng-web-lib'
import SummaryReferenceSection from './SummaryReferenceSection'
import { DialogContent, Grid } from '@material-ui/core'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function SummaryReferenceDialog(props) {
  const { onSave, onClose, open, references } = props

  const methods = useForm({
    defaultValues: {
      nbciusinvoiceSummaryReference: references
    }
  })

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSave(data.nbciusinvoiceSummaryReference)
        resolve()
      }, 500)
    })
  }

  return (
    <FormProvider {...methods}>
      <CngDialog
        customDialogContent={
          <DialogContent style={{ padding: 16 }}>
            <CngForm
              fieldLevel='form'
              formikProps={{
                initialValues: {
                  nbciusinvoiceSummaryReference: references
                },
                onSubmit: handleSubmit
              }}
              formState={FormState.COMPLETED}
              innerForm
              renderBodySection={(labelMap, shouldHideMap) => (
                <SummaryReferenceSection shouldHideMap={shouldHideMap} />
              )}
              renderButtonSection={() => <ButtonSection onClose={onClose} />}
            />
          </DialogContent>
        }
        dialogTitle='Manage other charges'
        fullWidth
        maxWidth='md'
        onClose={onClose}
        open={open}
        shouldShowCloseButton
      />
    </FormProvider>
  )
}

function ButtonSection(props) {
  const { onClose } = props

  const {
    formState: { isSubmitting }
  } = useFormContext()

  return (
    <Grid container justify='flex-end' spacing={2}>
      <Grid item xs='auto'>
        <CngButton disabled={isSubmitting} onClick={onClose}>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <CngButton disabled={isSubmitting} type='submit'>
          Save
        </CngButton>
      </Grid>
    </Grid>
  )
}

export default SummaryReferenceDialog
