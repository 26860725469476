import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    chemicalName: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    services: Yup.string()
      .nullable()
      .min(6, 'Min value needed is 6')
      .max(10, 'Max value allowed is 10')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    regNumber: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
