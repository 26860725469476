import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import FormProperties from './ReferenceFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import ReferenceFieldsPlaceholder from './ReferenceFieldsPlaceholder'
import _ from 'lodash'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function ReferencesSection(props) {
  const { title } = props

  const { getValues, setValue, watch } = useFormContext()
  const references = watch('nbciusinvoiceDetailsReference')
  const generatedIndex = useRef(0)

  function handleAddReference(data) {
    const references = [...getValues('nbciusinvoiceDetailsReference'), data]

    setValue('nbciusinvoiceDetailsReference', references)
  }

  function handleDeleteReference(index) {
    const references = [...getValues('nbciusinvoiceDetailsReference')]

    references.splice(index, 1)

    setValue('nbciusinvoiceDetailsReference', references)
  }

  function handleEditReference(index, data) {
    const references = [...getValues('nbciusinvoiceDetailsReference')]

    references[index] = data

    setValue('nbciusinvoiceDetailsReference', references)
  }

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(references)} title={title}>
      <Grid container spacing={2}>
        {_.isEmpty(references) ? (
          <Grid item xs={12}>
            <ReferenceFieldsPlaceholder onAdd={handleAddReference} />
          </Grid>
        ) : (
          references.map((reference, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <ReferenceEntry
                  references={reference}
                  onAdd={handleAddReference}
                  onDelete={() => handleDeleteReference(index)}
                  onEdit={(data) => handleEditReference(index, data)}
                />
              </Grid>
              {index + 1 !== references.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

function ReferenceEntry(props) {
  const { references, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: references })
  const {
    formState: { isDirty }
  } = methods

  function handleEditReference(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditReference)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...references, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(references)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ReferencesSection
