import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import CngField from '../../../../../components/cngcomponents/CngField'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DOTContent(props) {
  const { dot, translate } = props

  const theme = useTheme()
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.PORT_CODE
    )
    const behicleMake = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.BEHICLE_MAKE
    )
    const vehicleEligibilityNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.VEHICLE_ELIGIBILITY_NO
    )
    const eqipDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.EQIP_DESC
    )
    const customsEntryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.CUSTOMS_ENTRY_NO
    )
    const model = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.MODEL
    )
    const entryDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.ENTRY_DATE
    )
    const year = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.YEAR
    )
    const vehicleIdentificationNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.VEHICLE_IDENTIFICATION_NO
    )
    const registerImporterName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.REGISTER_IMPORTER_NAME
    )
    const nhtsaregNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.N_H_T_S_A_REG_NO
    )
    const importerName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.IMPORTER_NAME
    )
    const importerAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.IMPORTER_ADDRESS
    )
    const declarantName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DECLARANT_NAME
    )
    const declarantAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DECLARANT_ADDRESS
    )
    const dateSigned = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.DATE_SIGNED
    )
    const safety1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY1
    )
    const safety2A = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY2_A
    )
    const safety2B = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY2_B
    )
    const safety3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY3
    )
    const safety4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY4
    )
    const safety5 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY5
    )
    const safety6 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY6
    )
    const safety7 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY7
    )
    const safety8 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY8
    )
    const safety9 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY9
    )
    const safety10 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY10
    )
    const safety11 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY11
    )
    const safety12 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY12
    )
    const safety13 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsDOTForm.SAFETY13
    )

    return {
      portCode,
      behicleMake,
      vehicleEligibilityNo,
      eqipDesc,
      customsEntryNo,
      model,
      entryDate,
      year,
      vehicleIdentificationNo,
      registerImporterName,
      nhtsaregNo,
      importerName,
      importerAddress,
      declarantName,
      declarantAddress,
      dateSigned,
      safety1,
      safety2A,
      safety2B,
      safety3,
      safety4,
      safety5,
      safety6,
      safety7,
      safety8,
      safety9,
      safety10,
      safety11,
      safety12,
      safety13
    }
  }

  function renderSafetyField(name) {
    function getColor() {
      return dot[name] === 'Y'
        ? theme.palette.primary.main
        : theme.palette.error.main
    }

    function getIcon() {
      return dot[name] === 'Y' ? 'check' : 'ban'
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <FontAwesomeIcon
            color={getColor()}
            fixedWidth
            icon={['fal', getIcon()]}
          />
        </Grid>
        <Grid item xs>
          <Typography variant='body2'>
            {translatedTextsObject[name]}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.portCode}>
              {dot.portCode}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.customsEntryNo}>
              {dot.customsEntryNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.entryDate}>
              {dot.entryDate}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.behicleMake}>
              {dot.behicleMake}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.model}>
              {dot.model}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.year}>
              {dot.year}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.vehicleIdentificationNo}>
              {dot.vehicleIdentificationNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.vehicleEligibilityNo}>
              {dot.vehicleEligibilityNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.registerImporterName}>
              {dot.registerImporterName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.nhtsaregNo}>
              {dot.nhtsaregNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.eqipDesc}>
              {dot.eqipDesc}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Any person knowingly making a false declaration is subject to a
              fine of not more than $10,000 or imprisonment for not more than
              5 years or both (18 U.S.C. 1001).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CngSection title='Equipment code details'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Federal Motor Vehicle Safety, Bumper and Theft
                        Prevention Standards
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety1')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety2A')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety2B')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety3')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety4')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety5')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety6')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety7')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety8')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety9')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety10')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety11')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety12')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderSafetyField('safety13')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CngSection>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.importerName}>
              {dot.importerName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.importerAddress}>
              {dot.importerAddress}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.declarantName}>
              {dot.declarantName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.declarantAddress}>
              {dot.declarantAddress}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.dateSigned}>
              {dot.dateSigned}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DOTContent
