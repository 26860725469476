import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import moment from 'moment'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    portOfEmbarkation: Yup.string()
      .max(80, 'Max length allowed is 80')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    countryOfHealthCare: Yup.string().nullable(),
    shippingDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    arrivalDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    usaphisName: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisAddress: Yup.string()
      .nullable()
      .max(110, 'Max length allowed is 110')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisCity: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisStateCode: Yup.string().nullable(),
    usaphisCountryCode: Yup.string().nullable(),
    usaphisPostalCode: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(
        /^[a-zA-Z0-9-., ]*$/,
        'Cannot contain special characters like ),(,@ etc.'
      ),
    usaphisTelNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    deliveryInUS: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    importPermitNumbers: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisRemarks: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisPrintName: Yup.string()
      .nullable()
      .max(60, 'Max length allowed is 60')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    usaphisTitle: Yup.string().nullable(),
    vetDate: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    usaphiportOfEntry: Yup.string().nullable()
  })
}

export default makeValidationSchema
