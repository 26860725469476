import makeValidationSchema from './NaftaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngDateField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  blanketPeriodFrom: '',
  blanketPeriodTo: '',
  company: '',
  title: '',
  naftaDate: '',
  naftapartyName: '',
  contactTelNum: '',
  contactFax: '',
  email: '',
  cerifierType: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const blanketPeriodFrom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.BLANKET_PERIOD_FROM
    )
    const blanketPeriodTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.BLANKET_PERIOD_TO
    )
    const company = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.COMPANY
    )
    const naftaDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.NAFTA_DATE
    )
    const naftapartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.N_A_F_T_APARTY_NAME
    )
    const contactTelNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CONTACT_TEL_NUM
    )
    const contactFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CONTACT_FAX
    )
    const email = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.EMAIL
    )
    const cerifierType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NaftaForm.CERIFIER_TYPE
    )

    return {
      blanketPeriodFrom,
      blanketPeriodTo,
      company,
      naftaDate,
      naftapartyName,
      contactTelNum,
      contactFax,
      email,
      cerifierType
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.blanketPeriodFrom}>
        <CngDateField
          name='blanketPeriodFrom'
          label={translatedTextsObject.blanketPeriodFrom}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.blanketPeriodTo}>
        <CngDateField
          name='blanketPeriodTo'
          label={translatedTextsObject.blanketPeriodTo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.cerifierType}>
        <CngSelectField
          name='cerifierType'
          label={translatedTextsObject.cerifierType}
          disabled={disabled}
          size='small'
          items={[
            { text: 'IMPORTER', value: 'IR' },
            { text: 'EXPORTER', value: 'EX' },
            { text: 'PRODUCER', value: 'MP' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.naftapartyName}>
        <CngTextField
          name='naftapartyName'
          inputProps={{ maxLength: 60 }}
          label={translatedTextsObject.naftapartyName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.company}>
        <CngTextField
          name='company'
          inputProps={{ maxLength: 100 }}
          label={translatedTextsObject.company}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.title}>
        <CngTextField
          name='title'
          inputProps={{ maxLength: 50 }}
          label='Title'
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.contactTelNum}>
        <CngTextField
          name='contactTelNum'
          label={translatedTextsObject.contactTelNum}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.contactFax}>
        <CngTextField
          name='contactFax'
          label={translatedTextsObject.contactFax}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.email}>
        <CngTextField
          name='email'
          label={translatedTextsObject.email}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.naftaDate}>
        <CngDateField
          name='naftaDate'
          label={translatedTextsObject.naftaDate}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NaftaFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NaftaFormFormProperties
