import makeValidationSchema from './PackingListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'
import NaUSForeignPortAutocompleteField from '../../../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import Switch from '../../../../../components/Switch'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  otherRefNo: '',
  responsibleForCustoms: '',
  instructionsToBroker: '',
  containerized: 'N',
  billChargesTo: '',
  portOfLoading: '',
  flightDetails: '',
  placeOfDelivery: '',
  specialInstructions: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()

  const containerized = watch('containerized')
  const portOfLoading = watch('portOfLoading')
  useEffect(() => {
    setValue('containerized', containerized, { shouldDirty: true })
  }, [containerized])
  useEffect(() => {
    setValue('portOfLoading', portOfLoading, { shouldDirty: true })
  }, [portOfLoading])

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const otherRefNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.OTHER_REF_NO
    )
    const responsibleForCustoms = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.RESPONSIBLE_FOR_CUSTOMS
    )
    const instructionsToBroker = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.INSTRUCTIONS_TO_BROKER
    )
    const containerized = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.CONTAINERIZED
    )
    const billChargesTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.BILL_CHARGES_TO
    )
    const portOfLoading = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.PORT_OF_LOADING
    )
    const flightDetails = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.FLIGHT_DETAILS
    )
    const placeOfDelivery = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.PLACE_OF_DELIVERY
    )
    const specialInstructions = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.PackingList.SPECIAL_INSTRUCTIONS
    )

    return {
      otherRefNo,
      responsibleForCustoms,
      instructionsToBroker,
      containerized,
      billChargesTo,
      portOfLoading,
      flightDetails,
      placeOfDelivery,
      specialInstructions
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.otherRefNo}>
        <CngTextField
          name='otherRefNo'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.otherRefNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.portOfLoading}>
        <NaUSForeignPortAutocompleteField
          name='portOfLoading'
          label={translatedTextsObject.portOfLoading}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.responsibleForCustoms}>
        <CngTextField
          name='responsibleForCustoms'
          inputProps={{ maxLength: 20 }}
          label={translatedTextsObject.responsibleForCustoms}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.flightDetails}>
        <CngTextField
          name='flightDetails'
          inputProps={{ maxLength: 20 }}
          label={translatedTextsObject.flightDetails}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.instructionsToBroker}>
        <CngSelectField
          name='instructionsToBroker'
          label={translatedTextsObject.instructionsToBroker}
          disabled={disabled}
          items={[
            { text: 'Arrange Customs Clearance', value: 'Y' },
            { text: 'Inbond to Destination', value: 'N' }
          ]}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.placeOfDelivery}>
        <CngTextField
          name='placeOfDelivery'
          inputProps={{ maxLength: 20 }}
          label={translatedTextsObject.placeOfDelivery}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.billChargesTo}>
        <CngSelectField
          name='billChargesTo'
          label={translatedTextsObject.billChargesTo}
          disabled={disabled}
          items={[
            { text: 'Exporter', value: 'E' },
            { text: 'Sold To', value: 'S' },
            { text: 'Consignee', value: 'C' }
          ]}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.containerized}>
        <Switch
          checked={containerized === 'Y'}
          name='containerized'
          label={translatedTextsObject.containerized}
          disabled={disabled}
          fullWidth
          onChange={(event) => setValue('containerized', event.target.checked ? 'Y' : 'N')}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.specialInstructions}>
        <CngTextField
          name='specialInstructions'
          inputProps={{ maxLength: 200 }}
          label={translatedTextsObject.specialInstructions}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const PackingListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PackingListFormProperties
