import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import CngField from '../../../../../components/cngcomponents/CngField'
import Grid from '@material-ui/core/Grid'

function FCCContent(props) {
  const { fcc, translate } = props

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const deviceModel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DEVICE_MODEL
    )
    const tradeName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.TRADE_NAME
    )
    const fccId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.FCC_ID
    )
    const equipDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.EQUIP_DESC
    )
    const dateOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DATE_OF_ENTRY
    )
    const entryNO = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.ENTRY_N_O
    )
    const detailsPortOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DETAILS_PORT_OF_ENTRY
    )
    const htsNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.HTS_NO
    )
    const quantityOfItem = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.QUANTITY_OF_ITEM
    )

    return {
      deviceModel,
      tradeName,
      fccId,
      equipDesc,
      dateOfEntry,
      entryNO,
      detailsPortOfEntry,
      htsNo,
      quantityOfItem
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.deviceModel}>
          {fcc.deviceModel}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.tradeName}>
          {fcc.tradeName}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.dateOfEntry}>
          {fcc.dateOfEntry}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.entryNO}>
          {fcc.entryNO}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.detailsPortOfEntry}>
          {fcc.detailsPortOfEntry}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.fccId}>
          {fcc.fccId}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.htsNo}>
          {fcc.htsNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.quantityOfItem}>
          {fcc.quantityOfItem}
        </CngField>
      </Grid>
      <Grid item xs={12}>
        <CngField label={translatedTextsObject.equipDesc}>
          {fcc.equipDesc}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default FCCContent
