import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import makeValidationSchema from './SummaryReferenceMakeValidationSchema'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  additionorDeductionCode: '',
  refAmount: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const additionorDeductionCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.ADD_DED_CODE
    )
    const refAmount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceSummaryReference.REF_AMOUNT
    )

    return {
      additionorDeductionCode,
      refAmount,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.additionorDeductionCode}>
        <CngCodeMasterAutocompleteField
          name='additionorDeductionCode'
          label={translatedTextsObject.additionorDeductionCode}
          disabled={disabled}
          size='small'
          codeType='US_INV_SUMMARY_ADD_CODE'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.refAmount}>
        <CngTextField
          name='refAmount'
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.refAmount}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const SummaryReferenceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default SummaryReferenceFormProperties
