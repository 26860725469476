import makeValidationSchema from './TscaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import RadioButtonGroup from '../../../../../components/aciacehighway/RadioButtonGroup'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TscaDetailsSection from './TscaDetailsSection'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  tcsaname: '',
  tscaDate: '',
  title: '',
  phone: '',
  companyName: '',
  address1: '',
  address2: '',
  cityStateZip: '',
  choice1Or2: '',
  choice3: '',
  nbciusinvoiceTCSADetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap}) {
  const { setValue, watch } = useFormContext()

  const choice1Or2 = watch('choice1Or2')
  const choice3 = watch('choice3')
  const choice4 = watch('choice4')
  const nbciusinvoiceTCSADetails = watch('nbciusinvoiceTCSADetails')

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function choice1Or2Event(event) {
    setValue('choice1Or2', event.target.value, {
      shouldDirty: true
    })

    setValue('choice4', '', {
      shouldDirty: true
    })
  }

  function choice1Or2ClickEvent(event) {
    if (event.target.value === choice1Or2) {
      setValue('choice1Or2', '')
    }
  }

  function choice4Event(event) {
    if (choice1Or2 === '' || choice1Or2 === null) {
      setValue('choice4', event.target.checked ? 'CH4' : 'N', {
        shouldDirty: true
      })    
    }
  }

  function makeTranslatedTextsObject() {
    const tcsaname = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.NAME
    )
    const tscaDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.TSCA_DATE
    )
    const phone = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.PHONE
    )
    const companyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.COMPANY_NAME
    )
    const address1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.ADDRESS1
    )
    const address2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.ADDRESS2
    )
    const cityStateZip = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CITY_STATE_ZIP
    )
    const choice1Or2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE1_OR2
    )
    const choice3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE3
    )
    const nbciusinvoiceTCSADetails = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.TITLE
    )

    const choice4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE4
    )

    const email = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.EMAIL
    )

    return {
      tcsaname,
      tscaDate,
      phone,
      companyName,
      address1,
      address2,
      cityStateZip,
      choice1Or2,
      choice3,
      nbciusinvoiceTCSADetails,
      choice4,
      email
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.choice1Or2}>
            <RadioButtonGroup
              onChange={(event) =>
                choice1Or2Event(event)
              }
              onClick={(event) =>
                choice1Or2ClickEvent(event)
              }
              options={[
                {
                  label: (
                    <Typography variant='body2'>
                      <Typography
                        color='textSecondary'
                        component='span'
                        style={{ fontWeight: 600 }}
                      >
                        Positive certification:&nbsp;
                      </Typography>
                      <Typography component='span' style={{ fontWeight: 600 }}>
                        I certify that all chemical substances in this shipment
                        comply with all applicable rules or orders under TSCA
                        and that I am not offering a chemical substance for
                        entry in violation of TSCA or any applicable rule or
                        order under TSCA. (Applicable to TSCA Section 13 and TSCA Title VI.)
                      </Typography>
                    </Typography>
                  ),
                  value: 'CH1'
                },
                {
                  label: (
                    <Typography variant='body2'>
                      <Typography
                        color='textSecondary'
                        component='span'
                        style={{ fontWeight: 600 }}
                      >
                        Negative certification:&nbsp;
                      </Typography>
                      <Typography component='span' style={{ fontWeight: 600 }}>
                        I certify that all chemicals in this shipment are not
                        subject to TSCA.
                      </Typography>
                    </Typography>
                  ),
                  value: 'CH2'
                }
              ]}
              value={choice1Or2}
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap?.choice3}>
          <FormControlLabel
                control={
                  <Checkbox
                    checked={choice4 === 'CH4' && (choice1Or2 === '' || choice1Or2 === null)}
                    checkedIcon={
                      <FontAwesomeIcon icon={['fad', 'square-check']} />
                    }
                    icon={<FontAwesomeIcon icon={['fad', 'square']} />}
                  />
                }
                label={
                  <Typography
                    color='textSecondary'
                    variant='body2'
                    style={{ fontWeight: 600 }}
                  >
                    {translatedTextsObject.choice4}
                  </Typography>
                }
                onChange={(event) =>
                  choice4Event(event)
                }
              />
               </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.tcsaname}>
            <CngTextField
              name='tcsaname'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.tcsaname}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.title}>
            <CngTextField
              name='title'
              inputProps={{ maxLength: 50 }}
              label='Title'
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.phone}>
            <CngTextField
              name='phone'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.phone}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tscaDate}>
            <CngDateField
              name='tscaDate'
              label={translatedTextsObject.tscaDate}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.companyName}>
            <CngTextField
              name='companyName'
              inputProps={{ maxLength: 60 }}
              label={translatedTextsObject.companyName}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address1}>
            <CngTextField
              name='address1'
              inputProps={{ maxLength: 200 }}
              label={translatedTextsObject.address1}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.address2}>
            <CngTextField
              name='address2'
              inputProps={{ maxLength: 200 }}
              label={translatedTextsObject.address2}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.cityStateZip}>
            <CngTextField
              name='cityStateZip'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.cityStateZip}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.email}>
            <CngTextField
              name='email'
              inputProps={{ maxLength: 50 }}
              label={translatedTextsObject.email}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardHeader
            action={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice3 === 'CH3'}
                    checkedIcon={
                      <FontAwesomeIcon icon={['fad', 'square-check']} />
                    }
                    icon={<FontAwesomeIcon icon={['fad', 'square']} />}
                  />
                }
                label={
                  <Typography
                    color='textSecondary'
                    variant='body2'
                    style={{ fontWeight: 600 }}
                  >
                    {translatedTextsObject.choice3}
                  </Typography>
                }
                onChange={(event) =>
                  setValue('choice3', event.target.checked ? 'CH3' : 'N', {
                    shouldDirty: true
                  })
                }
              />
            }
            title={translatedTextsObject.nbciusinvoiceTCSADetails}
            titleTypographyProps={{ style: { fontWeight: 600 } }}
          />
          <CardContent>
            <TscaDetailsSection nbciusinvoiceTCSADetails={nbciusinvoiceTCSADetails} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const TSCAFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TSCAFormFormProperties
