import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import FormProperties from './USAPHISAnimalFormProperties'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import USAPHISAnimalFieldsPlaceholder from './USAPHISAnimalFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function USAPHISAnimalSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const usaphisAnimal = watch('nbciusinvoiceUSAPHISAnimalFormDTO')

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function handleAddUSAPHISAnimal(data) {
    const usaphisAnimal = [
      ...getValues('nbciusinvoiceUSAPHISAnimalFormDTO'),
      data
    ]

    setValue('nbciusinvoiceUSAPHISAnimalFormDTO', usaphisAnimal)
  }

  function handleDeleteUSAPHISAnimal(index) {
    const usaphisAnimal = [...getValues('nbciusinvoiceUSAPHISAnimalFormDTO')]

    usaphisAnimal.splice(index, 1)

    setValue('nbciusinvoiceUSAPHISAnimalFormDTO', usaphisAnimal)
  }

  function handleEditUSAPHISAnimal(index, data) {
    const usaphisAnimal = [...getValues('nbciusinvoiceUSAPHISAnimalFormDTO')]

    usaphisAnimal[index] = data

    setValue('nbciusinvoiceUSAPHISAnimalFormDTO', usaphisAnimal)
  }

  function makeTranslatedTextsObject() {
    const usaphisAnimal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.TITLE
    )

    return { usaphisAnimal }
  }

  return (
    <CngSection title={translatedTextsObject.usaphisAnimal}>
      <Grid container spacing={2}>
        {_.isEmpty(usaphisAnimal) ? (
          <Grid item xs={12}>
            <USAPHISAnimalFieldsPlaceholder onAdd={handleAddUSAPHISAnimal} />
          </Grid>
        ) : (
          usaphisAnimal.map((item, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <USAPHISAnimalEntry
                  usaphisAnimal={item}
                  onAdd={handleAddUSAPHISAnimal}
                  onDelete={() => handleDeleteUSAPHISAnimal(index)}
                  onEdit={(data) => handleEditUSAPHISAnimal(index, data)}
                />
              </Grid>
              {index + 1 !== usaphisAnimal.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function USAPHISAnimalEntry(props) {
  const { usaphisAnimal, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: usaphisAnimal })
  const {
    formState: { isDirty }
  } = methods

  function handleEditUSAPHISAnimal(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditUSAPHISAnimal)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() =>
                          onAdd({ ...usaphisAnimal, id: undefined })
                        }
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(usaphisAnimal)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default USAPHISAnimalSection
