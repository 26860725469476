import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import FormProperties from './BolFormFormProperties'
import BolFormFieldsPlaceholder from './BolFormFieldsPlaceholder'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Box, Card, Divider, Grid, Tooltip } from '@material-ui/core'
import useCustomYupValidationResolver from '../../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function BolFormSection(props) {
  const { bolForm: bolFormProp, onClose, onSubmit } = props

  const generatedIndex = useRef(0)
  const methods = useForm({ defaultValues: { bolForm: bolFormProp } })
  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch
  } = methods
  const bolForm = watch('bolForm')

  function handleAddBolForm(data) {
    const bolForm = [...getValues('bolForm'), data]

    setValue('bolForm', bolForm)
  }

  function handleDeleteBolForm(index) {
    const bolForm = [...getValues('bolForm')]

    bolForm.splice(index, 1)

    setValue('bolForm', bolForm)
  }

  function handleEditBolForm(index, data) {
    const bolForm = [...getValues('bolForm')]

    bolForm[index] = data

    setValue('bolForm', bolForm)
  }

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data.bolForm)
        resolve()
      }, 500)
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Grid container spacing={2}>
            {_.isEmpty(bolForm) ? (
              <Grid item xs={12}>
                <Box padding={2}>
                  <BolFormFieldsPlaceholder onAdd={handleAddBolForm} />
                </Box>
              </Grid>
            ) : (
              bolForm.map((item, index) => (
                <React.Fragment key={++generatedIndex.current}>
                  <Grid item xs={12}>
                    <Box padding={2}>
                      <BolFormEntry
                        bolForm={item}
                        onAdd={handleAddBolForm}
                        onDelete={() => handleDeleteBolForm(index)}
                        onEdit={(data) => handleEditBolForm(index, data)}
                      />
                    </Box>
                  </Grid>
                  {index + 1 !== bolForm.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              disabled={isSubmitting}
              onClick={() => methods.reset(bolForm || initialValues)}
            >
              Clear all
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={isSubmitting}
                  onClick={onClose}
                >
                  Discard
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function BolFormEntry(props) {
  const { bolForm, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const methods = useForm({
    defaultValues: bolForm,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditBolForm(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditBolForm)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...bolForm, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(bolForm)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default BolFormSection
