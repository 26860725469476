import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import HelperText from '../../../../views/common/HelperText'
import {
  Box,
  Collapse,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core'
import _ from 'lodash'

const {
  form: {
    field: { CngSwitchField }
  }
} = components

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiGrid-root.header': {
      '& .MuiSwitch-root': {
        margin: 0
      }
    }
  }
}))

function PartyInfoCollapsibleSection(props) {
  const {
    children,
    defaultExpanded = true,
    helperText,
    onExpandedChange = () => {},
    subheader,
    title
  } = props

  const [expanded, setExpanded] = useState(defaultExpanded)
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Box padding={2}>
        <Grid className='header' alignItems='center' container spacing={2}>
          {helperText && (
            <Grid item xs='auto'>
              <HelperText helperText={helperText} />
            </Grid>
          )}
          <Grid item xs>
            {title && _.isString(title) ? (
              <Typography variant='h5' style={{ fontWeight: 600 }}>
                {title}
              </Typography>
            ) : (
              title
            )}
            {subheader && _.isString(subheader) ? (
              <Typography variant='body2'>{subheader}</Typography>
            ) : (
              subheader
            )}
          </Grid>
          <Grid item xs='auto'>
            <CngSwitchField
              checked={expanded}
              onChange={(_, value) => {
                setExpanded(value)
                onExpandedChange(value)
              }}
            />
          </Grid>
        </Grid>
        <Collapse in={expanded}>
          <Box pt={2}>{children}</Box>
        </Collapse>
      </Box>
    </Paper>
  )
}

export default PartyInfoCollapsibleSection
