import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexDigitWithoutDecimal = '^[0-9]{0,4}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 4 without decimal'
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    portCode: Yup.string().nullable(),
    behicleMake: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    vehicleEligibilityNo: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    eqipDesc: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    customsEntryNo: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    model: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    entryDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    year: Yup.string()
      .nullable()
      .max(4, 'Max length allowed is 4')
      .matches(regexDigitWithoutDecimal, errMsgDigitWithoutDecimal),
    vehicleIdentificationNo: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    registerImporterName: Yup.string()
      .nullable()
      .max(35, 'Max length allowed is 35')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
      nhtsaregNo: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    importerName: Yup.string()
      .nullable()
      .max(35, 'Max length allowed is 35')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    importerAddress: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    declarantName: Yup.string()
      .nullable()
      .max(35, 'Max length allowed is 35')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    declarantAddress: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    dateSigned: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    safety1: Yup.string().nullable(),
    safety2A: Yup.string().nullable(),
    safety2B: Yup.string().nullable(),
    safety3: Yup.string().nullable(),
    safety4: Yup.string().nullable(),
    safety5: Yup.string().nullable(),
    safety6: Yup.string().nullable(),
    safety7: Yup.string().nullable(),
    safety8: Yup.string().nullable(),
    safety9: Yup.string().nullable(),
    safety10: Yup.string().nullable(),
    safety11: Yup.string().nullable(),
    safety12: Yup.string().nullable(),
    safety13: Yup.string().nullable()
  })
}

export default makeValidationSchema
