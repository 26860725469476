import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexDigitWithoutDecimal = '^[0-9]{0,10}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 10 digits'
  const regexDigitWithNumerics = '^[0-9]{0,15}$'
  const errMsgDigitWithNumerics =
    'Please enter only numeric values with up to 15 digits'
  const regexDigitWithDecimal = ValidationRegex.regex7DigitWith4Decimal
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 7 digits and  4 decimal'
  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    htsNo: Yup.string()
      .nullable()
      .matches(regexDigitWithoutDecimal, errMsgDigitWithoutDecimal)
      .max(10, 'Max length allowed is 40'),
    enteredValue: Yup.string()
      .nullable()
      .matches(regexDigitWithNumerics, errMsgDigitWithNumerics)
      .max(15, 'Max length allowed is 40'),
    componentOfArticle: Yup.string()
      .nullable()
      .max(40, 'Max length allowed is 40')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    genusGame: Yup.string()
      .nullable()
      .max(40, 'Max length allowed is 40')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    speciesName: Yup.string()
      .nullable()
      .max(40, 'Max length allowed is 40')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    country: Yup.string().nullable(),
    quantity: Yup.string()
      .nullable()
      .max(12, 'Max length allowed is 12')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    uom: Yup.string()
      .nullable()
      .max(10, 'Max length allowed is 10')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    percentOfRecycle: Yup.string()
      .nullable()
      .max(12, 'Max length allowed is 12')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal)
  })
}

export default makeValidationSchema
