import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import CngField from '../../../../../components/cngcomponents/CngField'
import Alert from '../../../../../components/Alert'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function EPA352021Content(props) {
  const { epa352021, translate } = props

  const theme = useTheme()
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PORT_CODE
    )
    const entityDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENTITY_DATE
    )
    const entryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENTRY_NO
    )
    const engineBuildDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENGINE_BUILD_DATE
    )
    const onEngine = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ON_ENGINE
    )
    const other = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.OTHER
    )
    const engineManufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENGINE_MANUFACTURER
    )
    const vehicleManufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.VEHICLE_MANUFACTURER
    )
    const printedName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PRINTED_NAME
    )
    const printedDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PRINTED_DATE
    )
    const equipmentTypeA = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_A
    )
    const equipmentTypeB = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_B
    )
    const equipmentTypeC = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_C
    )
    const equipmentTypeD = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_D
    )
    const equipmentTypeE = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_E
    )
    const equipmentTypeF = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_F
    )
    const equipmentTypeG = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_G
    )
    const equipmentTypeH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_H
    )
    const equipmentType1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE1
    )
    const equipmentType2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE2
    )
    const equipmentType3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE3
    )
    const equipmentType4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE4
    )
    const equipmentType5 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE5
    )
    const equipmentType6 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE6
    )
    const equipmentType7 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE7
    )
    const equipmentType8 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE8
    )
    const equipmentType9 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE9
    )
    const equipmentType10 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE10
    )
    const equipmentType11 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE11
    )
    const equipmentType12 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE12
    )
    const equipmentType13 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE13
    )
    const equipmentType14 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE14
    )
    const equipmentType15 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE15
    )
    const equipmentType16 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE16
    )
    const equipmentType17 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE17
    )
    const equipmentType18 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE18
    )
    const equipmentType19 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE19
    )
    const equipmentType20 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE20
    )
    const equipmentType21 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE21
    )
    const equipmentType22 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE22
    )
    const equipmentType23 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE23
    )
    const equipmentType24 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24
    )
    const equipmentType25 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE25
    )
    const equipmentType26A = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_A
    )
    const equipmentType26B = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_B
    )
    const equipmentType26C = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_C
    )
    const equipmentType24Power = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24_POWER
    )
    const equipmentType24Watts = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24_WATTS
    )

    return {
      portCode,
      entityDate,
      entryNo,
      engineBuildDate,
      onEngine,
      other,
      engineManufacturer,
      vehicleManufacturer,
      printedName,
      printedDate,
      equipmentTypeA,
      equipmentTypeB,
      equipmentTypeC,
      equipmentTypeD,
      equipmentTypeE,
      equipmentTypeF,
      equipmentTypeG,
      equipmentTypeH,
      equipmentType1,
      equipmentType2,
      equipmentType3,
      equipmentType4,
      equipmentType5,
      equipmentType6,
      equipmentType7,
      equipmentType8,
      equipmentType9,
      equipmentType10,
      equipmentType11,
      equipmentType12,
      equipmentType13,
      equipmentType14,
      equipmentType15,
      equipmentType16,
      equipmentType17,
      equipmentType18,
      equipmentType19,
      equipmentType20,
      equipmentType21,
      equipmentType22,
      equipmentType23,
      equipmentType24,
      equipmentType25,
      equipmentType26A,
      equipmentType26B,
      equipmentType26C,
      equipmentType24Power,
      equipmentType24Watts
    }
  }

  function renderEquipmentCodeField(name) {
    function getColor() {
      return epa352021[name] === 'Y'
        ? theme.palette.primary.main
        : theme.palette.error.main
    }

    function getIcon() {
      return epa352021[name] === 'Y' ? 'check' : 'ban'
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <FontAwesomeIcon
            color={getColor()}
            fixedWidth
            icon={['fal', getIcon()]}
          />
        </Grid>
        <Grid item xs>
          <Typography variant='body2'>
            {translatedTextsObject[name]}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Declaration of Stationary, Nonroad or Heavy-duty Highway Engine,
              Nonroad Vehicle or Equipment, or Stationary Equipment
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portCode}>
                  {epa352021.portCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entityDate}>
                  {epa352021.entityDate}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryNo}>
                  {epa352021.entryNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.engineBuildDate}>
                  {epa352021.engineBuildDate}
                </CngField>
              </Grid>
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.engineManufacturer}>
                  {epa352021.engineManufacturer}
                </CngField>
              </Grid>
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.vehicleManufacturer}>
                  {epa352021.vehicleManufacturer}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <FontAwesomeIcon
                      color={
                        epa352021.onEngine === 'Y'
                          ? theme.palette.primary.main
                          : theme.palette.error.main
                      }
                      fixedWidth
                      icon={[
                        'fal',
                        epa352021.onEngine === 'Y' ? 'check' : 'ban'
                      ]}
                    />
                  </Grid>
                  <Grid item xs>
                    {translatedTextsObject.onEngine}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.other}>
                  {epa352021.other}
                </CngField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Names, Addresses, and Telephone Numbers of Relevant Parties
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <strong>Certification:</strong>&nbsp;I certify that I have read
              and understand the purpose of this form, the penalties for falsely
              declaring information, for providing misleading information, or
              for concealing a material fact. The information I have provided is
              correct, and all required attachments are appended to this form. I
              authorise EPA Enforcement Officers to conduct inspections or
              testing permitted by the Clean Air Act. I am the owner, importer,
              or agent for the owner or importer.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.printedDate}>
                  {epa352021.printedDate}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CngField label={translatedTextsObject.printedName}>
                  {epa352021.printedName}
                </CngField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Equipment code details'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Identify the appropriate type of highway or nonroad engine,
                    vehicle, or equipment you are importing the following list
                    of products
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeA')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeB')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeC')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeD')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeE')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeF')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeG')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentTypeH')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Check one of the following Codes to indicate the provison
                    under which you are importing the engine, vehicle, or
                    equipment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {renderEquipmentCodeField('equipmentType1')}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Permanent Exemptions for Nonconforming Engines
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType2')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType3')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType4')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType5')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType6')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType7')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType8')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType9')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Temporary Exemptions for Nonconforming Engines
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      style={{ fontWeight: 600 }}
                    >
                      The following temporary exemptions apply for importing
                      nonconforming engines. EPA requests bonding with U.S.
                      Customs Service for the full value of the iomported
                      products to make sure you comply with applicable
                      requirements.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType10')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType11')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType12')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType13')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType14')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType15')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType16')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Importation of Engines Not Yet Subject to U.S. EPA Emission
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType17')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType18')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Importation of Engines Excluded from U.S. EPA Emission
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType19')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType20')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType21')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType22')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType23')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Exemptions for Specific Engine Categories or Other Special
                    Cases
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {renderEquipmentCodeField('equipmentType24')}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.equipmentType24Power}>
                            {epa352021.equipmentType24Power}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.equipmentType24Watts}>
                            {epa352021.equipmentType24Watts}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType25')}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    26. Engine imported by an Independent Commercial Importer
                    recognized by EPAO. nly for Categories A and D above.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType26A')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType26B')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {renderEquipmentCodeField('equipmentType26C')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Alert
                        severity='info'
                        icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                      >
                        <strong>NOTE:</strong>&nbsp;Under 26a and 26b, you may
                        import up to 5 highway engines and 5 nonroad engines in
                        a given model year that are certified to standards based
                        on an engines original production year. You may import
                        any number of engines certified to standards based on
                        the year engine is modified. See 40 CFR 85.1503 and
                        89.603.
                      </Alert>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default EPA352021Content
