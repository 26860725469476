import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../../lookups/USInvoiceLookupsContext'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import CngField from '../../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../../components/aciacehighway/TinyChip'
import DeclarationsViewContent from './DeclarationsViewContent'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import moment from 'moment'

function InvoiceDetailsViewContent(props) {
  const { invoiceDetail } = props

  const { getLookupValue, getCountryStateLabel } = useContext(USInvoiceLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const partNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PART_NUMBER
    )
    const productDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_DESCRIPTION
    )
    const fdaProductCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.FDA_PRODUCT_CODE
    )
    const countryOfOriginCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.COUNTRY_OF_ORIGIN_CODE
    )
    const stateOfOrigin = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.STATE_OF_ORIGIN
    )
    const quantityInvoiced = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.QUANTITY_INVOICED
    )
    const uomQuantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UOM_QUANTITY
    )
    const htsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.HTS_CODE
    )
    const unitPrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UNIT_PRICE
    )
    const netWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT
    )
    const netWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT_UOM
    )
    const grossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROSS_WEIGHT_UOM
    )
    const lineItemTotal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.LINE_ITEM_TOTAL
    )
    const markPackLoad = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MARK_PACK_LOAD
    )
    const noOfPackages = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NO_OF_PACKAGES
    )
    const packageType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PACKAGE_TYPE
    )
    const productSize = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_SIZE
    )
    const admname = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.ADMNAME
    )
    const admTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.ADM_TELEPHONE_NUMBER
    )
    const naftaCriteria = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NAFTA_CRITERIA
    )
    const dateRangeFrom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DATE_RANGE_FROM
    )
    const dateRangeTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DATE_RANGE_TO
    )
    const netCost = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_COST
    )
    const isProducer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.IS_PRODUCER
    )
    const includeProdNafta = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.INCLUDE_PROD_NAFTA
    )
    const manufacturingLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_LABEL
    )
    const manufacturingPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_PARTY_NAME
    )
    const manufacturingMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_M_I_D
    )
    const manufacturingFDARegNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_F_D_A_REG_NUM
    )
    const growerLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_LABEL
    )
    const growerPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_PARTY_NAME
    )
    const growerMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_M_I_D
    )
    const growerFDARegNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_F_D_A_REG_NUMBER
    )
    const consolidatorLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_LABEL
    )
    const consolidatorPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_PARTY_NAME
    )
    const consolidatorMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_M_I_D
    )
    const consolidatorFDARegNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_F_D_A_REG_NUMBER
    )
    const consPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_NAME
    )
    const consPartyIdentifierNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_IDENTIFIER_NUMBER
    )
    const consPfdaRegNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_FDA_REG_NO
    )
    const consPartyAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_ADDRESS
    )
    const consPartyCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_CITY
    )
    const consPartyState = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_STATE
    )
    const consPartyCountry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_COUNTRY
    )
    const consPartyPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_POSTAL_CODE
    )
    const consPartyLocalContactName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_LOCAL_CONTACT_NAME
    )
    const consPartyTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_TELEPHONE_NUMBER
    )
    const consPartyFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_FAX
    )
    const consPdba = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DBA
    )
    const consPartyEmail = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_EMAIL
    )
    const invoiceDetailsReference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.TITLE
    )
    const referenceType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REFERENCE_TYPE
    )
    const referenceIdentification = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REFERENCE_IDENTIFICATION
    )
    const refDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REF_DESCRIPTION
    )
    const invoiceDetailsService = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.TITLE
    )
    const additionDeductionCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.ADDITION_DEDUCTION_CODE
    )
    const amount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.AMOUNT
    )

    return {
      partNumber,
      productDescription,
      fdaProductCode,
      countryOfOriginCode,
      stateOfOrigin,
      quantityInvoiced,
      uomQuantity,
      htsCode,
      unitPrice,
      netWeight,
      netWeightUom,
      grossWeight,
      grossWeightUom,
      lineItemTotal,
      markPackLoad,
      noOfPackages,
      packageType,
      productSize,
      admname,
      admTelephoneNumber,
      naftaCriteria,
      dateRangeFrom,
      dateRangeTo,
      netCost,
      isProducer,
      includeProdNafta,
      manufacturingLabel,
      manufacturingPartyName,
      manufacturingMID,
      manufacturingFDARegNum,
      growerLabel,
      growerPartyName,
      growerMID,
      growerFDARegNumber,
      consolidatorLabel,
      consolidatorPartyName,
      consolidatorMID,
      consolidatorFDARegNumber,
      consPartyName,
      consPartyIdentifierNumber,
      consPartyAddress,
      consPartyCity,
      consPartyState,
      consPartyCountry,
      consPartyPostalCode,
      consPartyLocalContactName,
      consPartyTelephoneNumber,
      consPartyFax,
      consPdba,
      consPfdaRegNum,
      consPartyEmail,
      invoiceDetailsReference,
      referenceType,
      referenceIdentification,
      refDescription,
      invoiceDetailsService,
      additionDeductionCode,
      amount,
    }
  }

  if (!invoiceDetail) return null

  const {
    nbciusinvoiceDetailsEPA35201Form: epa35201,
    nbciusinvoiceDetailsEPA352021Form: epa352021,
    nbciusinvoiceDetailsFCCForm: fcc,
    nbciusinvoiceDetailsFDAForm: fda,
    nbciusinvoiceDetailsDOTForm: dot
  } = invoiceDetail

  function getCardHeaderBackgroundColor() {
    return theme.palette.background?.sectionOddBg || theme.palette.grey[200]
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title='Line item information'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.partNumber}>
                    {invoiceDetail.partNumber}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                  <CngField label={translatedTextsObject.productDescription}>
                    {invoiceDetail.productDescription}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.fdaProductCode}>
                    {invoiceDetail.fdaProductCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.countryOfOriginCode}>
                    {getLookupValue('country', invoiceDetail.countryOfOriginCode)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.stateOfOrigin}>
                    {getCountryStateLabel(invoiceDetail.countryOfOriginCode, invoiceDetail.stateOfOrigin)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.quantityInvoiced}>
                    {invoiceDetail.quantityInvoiced}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.uomQuantity}>
                    {invoiceDetail.uomQuantity}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.unitPrice}>
                    {invoiceDetail.unitPrice}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.htsCode}>
                    {invoiceDetail.htsCode}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Party Information'>
                <Card variant='outlined'>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.growerLabel}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box p={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.growerPartyName}>
                                  {invoiceDetail.growerPartyName}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.growerMID}>
                                  {invoiceDetail.growerMID}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.growerFDARegNumber}>
                                  {invoiceDetail.growerFDARegNumber}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.manufacturingLabel}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box p={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.manufacturingPartyName}>
                                  {invoiceDetail.manufacturingPartyName}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.manufacturingMID}>
                                  {invoiceDetail.manufacturingMID}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.manufacturingFDARegNum}>
                                  {invoiceDetail.manufacturingFDARegNum}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                            <Typography style={{ fontWeight: 600 }}>
                              {translatedTextsObject.consolidatorLabel}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box p={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.consolidatorPartyName}>
                                  {invoiceDetail.consolidatorPartyName}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consolidatorMID}>
                                  {invoiceDetail.consolidatorMID}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consolidatorFDARegNumber}>
                                  {invoiceDetail.consolidatorFDARegNumber}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                            <Typography style={{ fontWeight: 600 }}>
                              Consignee
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box p={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <CngField label={translatedTextsObject.consPartyName}>
                                  {invoiceDetail.consPartyName}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyIdentifierNumber}>
                                  {invoiceDetail.consPartyIdentifierNumber}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPfdaRegNum}>
                                  {invoiceDetail.consPfdaRegNum}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPdba}>
                                  {invoiceDetail.consPdba}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyAddress}>
                                  {invoiceDetail.consPartyAddress}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyCity}>
                                  {invoiceDetail.consPartyCity}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyCountry}>
                                  {getLookupValue('country', invoiceDetail.consPartyCountry)}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyState}>
                                  {invoiceDetail.consPartyState}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyPostalCode}>
                                  {invoiceDetail.consPartyPostalCode}
                                </CngField>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid alignItems='center' container spacing={2}>
                                  <Grid item xs='auto'>
                                    <Typography color='textSecondary' variant='body2'>
                                      Local Contact Information
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyLocalContactName}>
                                  {invoiceDetail.consPartyLocalContactName}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyTelephoneNumber}>
                                  {invoiceDetail.consPartyTelephoneNumber}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyFax}>
                                  {invoiceDetail.consPartyFax}
                                </CngField>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <CngField label={translatedTextsObject.consPartyEmail}>
                                  {invoiceDetail.consPartyEmail}
                                </CngField>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Weight Information'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.netWeight}>
                      {invoiceDetail.netWeight}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.netWeightUom}>
                      <Typography component='div' variant='inherit'>
                        {getLookupValue('weightUom', invoiceDetail.netWeightUom)}
                        <TinyChip label={invoiceDetail.netWeightUom} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.grossWeight}>
                      {invoiceDetail.grossWeight}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.grossWeightUom}>
                      <Typography component='div' variant='inherit'>
                        {getLookupValue('weightUom', invoiceDetail.grossWeightUom)}
                        <TinyChip label={invoiceDetail.grossWeightUom} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.lineItemTotal}>
                      {invoiceDetail.lineItemTotal}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.noOfPackages}>
                      {invoiceDetail.noOfPackages}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.packageType}>
                      {invoiceDetail.packageType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.productSize}>
                      {invoiceDetail.productSize}
                    </CngField>
                  </Grid>
                  <Grid item xs={12}>
                    <CngField label={translatedTextsObject.markPackLoad}>
                      {invoiceDetail.markPackLoad}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Administrative communication contact'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.admname}>
                      {invoiceDetail.admname}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.admTelephoneNumber}>
                      {invoiceDetail.admTelephoneNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs='auto'>
                        <FontAwesomeIcon
                          color={
                            invoiceDetail.includeProdNafta === 'Y'
                              ? theme.palette.primary.main
                              : theme.palette.error.main
                          }
                          icon={[
                            'fal',
                            invoiceDetail.includeProdNafta === 'Y'
                              ? 'check'
                              : 'ban'
                          ]}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2'>
                          {translatedTextsObject.includeProdNafta} - preference
                          criteria&nbsp;
                          <Typography color='primary' variant='inherit'>
                            {invoiceDetail.naftaCriteria}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSection title='Include USMCA product'>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.dateRangeFrom}>
                            {invoiceDetail.dateRangeFrom &&
                              moment(invoiceDetail.dateRangeFrom).format('D MMM YYYY')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.dateRangeTo}>
                            {invoiceDetail.dateRangeTo &&
                              moment(invoiceDetail.dateRangeTo).format('D MMM YYYY')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.netCost}>
                            {getLookupValue('netCost', invoiceDetail.netCost)}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Grid container spacing={2}>
                            <Grid item xs='auto'>
                              <FontAwesomeIcon
                                color={
                                  invoiceDetail.isProducer === 'Y'
                                    ? theme.palette.primary.main
                                    : theme.palette.error.main
                                }
                                icon={[
                                  'fal',
                                  invoiceDetail.isProducer === 'Y'
                                    ? 'check'
                                    : 'ban'
                                ]}
                              />
                            </Grid>
                            <Grid item xs>
                              <Typography variant='body2'>
                                {translatedTextsObject.isProducer}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CngSection>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Other information'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CngSection title={translatedTextsObject.invoiceDetailsReference}>
                      {!_.isEmpty(invoiceDetail.nbciusinvoiceDetailsReference) ? (
                        <Grid container spacing={2}>
                          {invoiceDetail.nbciusinvoiceDetailsReference.map(
                            (reference, index, references) => (
                              <React.Fragment key={reference.id}>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                      <CngField label={translatedTextsObject.referenceType}>
                                        {getLookupValue('referenceType', reference.referenceType)}
                                      </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <CngField label={translatedTextsObject.referenceIdentification}>
                                        {reference.referenceIdentification}
                                      </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <CngField label={translatedTextsObject.refDescription}>
                                        {reference.refDescription}
                                      </CngField>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {index + 1 < references.length && (
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </Grid>
                      ) : (
                        <Typography variant='body2' color='textSecondary'>
                          No records to display
                        </Typography>
                      )}
                    </CngSection>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSection title={translatedTextsObject.invoiceDetailsService}>
                      {!_.isEmpty(invoiceDetail.nbciusinvoiceDetailsService) ? (
                        <Grid container spacing={2}>
                          {invoiceDetail.nbciusinvoiceDetailsService.map(
                            (service, index, services) => (
                              <React.Fragment key={service.id}>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <CngField label={translatedTextsObject.additionDeductionCode}>
                                        {getLookupValue('additionDeductionCode', service.additionDeductionCode)}
                                      </CngField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <CngField label={translatedTextsObject.amount}>
                                        {service.amount}
                                      </CngField>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {index + 1 < services.length && (
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </Grid>
                      ) : (
                        <Typography variant='body2' color='textSecondary'>
                          No records to display
                        </Typography>
                      )}
                    </CngSection>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <DeclarationsViewContent
          epa35201={epa35201}
          epa352021={epa352021}
          fcc={fcc}
          fda={fda}
          dot={dot}
        />
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsViewContent
