import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexDigitWithDecimal = ValidationRegex.regex4DigitWith2Decimal
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 4 digits and  2 decimal'
  const regexDigitWith2Decimal = ValidationRegex.regex9DigitWith2Decimal
  const errMsgDigitWith2Decimal =
    'Please enter only numeric values with up to 9 digits and  2 decimal'
  const regex6DigitWith2Decimal = ValidationRegex.regex6DigitWith2Decimal
  const errMsg6DigitWith2Decimal =
    'Please enter only numeric values with up to 6 digits and  2 decimal'
  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    containerNo: Yup.string()
      .nullable()
      .max(30, 'Max values allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    freightCharges: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    placeOfLadding: Yup.string()
      .nullable()
      .max(30, 'Max values allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    deliveryInstrctions: Yup.string()
      .nullable()
      .max(50, 'Max values allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    codAmount: Yup.string()
      .nullable()
      .max(7, 'Max values allowed is 7')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    bolCurrency: Yup.string().nullable(),
    declaredValueOfShipment: Yup.string()
      .nullable()
      .max(12, 'Max values allowed is 12')
      .matches(regexDigitWith2Decimal, errMsgDigitWith2Decimal),
    chargeTo: Yup.string()
      .nullable()
      .max(50, 'Max values allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    releaseValue: Yup.string()
      .nullable()
      .max(9, 'Max values allowed is 9')
      .matches(regex6DigitWith2Decimal, errMsg6DigitWith2Decimal)
  })
}

export default makeValidationSchema
