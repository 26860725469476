import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CngField from '../../../../components/cngcomponents/CngField'
import { Divider, Grid, Typography, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TscaFormViewContent(props) {
  const { tscaform } = props

  const theme = useTheme()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const tcsaname = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.NAME
    )
    const tscaDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.TSCA_DATE
    )
    const phone = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.PHONE
    )
    const companyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.COMPANY_NAME
    )
    const address1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.ADDRESS1
    )
    const address2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.ADDRESS2
    )
    const cityStateZip = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CITY_STATE_ZIP
    )
    const choice1Or2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE1_OR2
    )
    const choice3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE3
    )
    const tscaDetails = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.TITLE
    )
    const chemicalName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.CHEMICAL_NAME
    )
    const services = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.SERVICES
    )
    const regNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.REG_NUMBER
    )

    const choice4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.CHOICE4
    )

    const email = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TSCAForm.EMAIL
    )

    return {
      tcsaname,
      tscaDate,
      phone,
      companyName,
      address1,
      address2,
      cityStateZip,
      choice1Or2,
      choice3,
      tscaDetails,
      chemicalName,
      services,
      regNum,
      choice4,
      email
    }
  }

  function renderCertificationType() {
    const isPositive = tscaform.choice1Or2 === 'CH1'

    const positiveMessage =
      'I certify that all chemical substances in this shipment comply with all applicable rules or orders under TSCA and that I am not offering a chemical substance for entry in violation of TSCA or any applicable rule or order under TSCA. (Applicable to TSCA Section 13 and TSCA Title VI.)'

    const negativeMessage =
      'I certify that all chemicals in this shipment are not subject to TSCA.'

    return (
      <Typography variant='body2'>
        <Typography
          color='textSecondary'
          component='span'
          style={{ fontWeight: 600 }}
          variant='inherit'
        >
          {isPositive ? 'Positive' : 'Negative'} certification:&nbsp;
        </Typography>
        <Typography component='span' style={{ fontWeight: 600 }} variant='inherit'>
          {isPositive ? positiveMessage : negativeMessage}
        </Typography>
      </Typography>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {renderCertificationType()}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
              <Grid item xs='auto'>
                <FontAwesomeIcon
                  color={
                    tscaform.choice4 === 'CH4'
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                  fixedWidth
                  icon={['fal', tscaform.choice4 === 'CH4' ? 'check' : 'ban']}
                />
              </Grid>
              <Grid item xs>
                <Typography variant='body2'>
                  {translatedTextsObject.choice4}
                </Typography>
              </Grid>
            </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.tcsaname}>
              {tscaform.tcsaname}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label='Title'>
              {tscaform.title}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.phone}>
              {tscaform.phone}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.companyName}>
              {tscaform.companyName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.address1}>
              {tscaform.address1}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.address2}>
              {tscaform.address2}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.cityStateZip}>
              {tscaform.cityStateZip}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.email}>
              {tscaform.email}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          action={
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <FontAwesomeIcon
                  color={
                    tscaform.choice3 === 'CH3'
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                  fixedWidth
                  icon={['fal', tscaform.choice3 === 'CH3' ? 'check' : 'ban']}
                />
              </Grid>
              <Grid item xs>
                <Typography variant='body2'>
                  {translatedTextsObject.choice3}
                </Typography>
              </Grid>
            </Grid>
          }
          title={translatedTextsObject.tscaDetails}
        >
          {!_.isEmpty(tscaform.nbciusinvoiceTCSADetails) ? (
            <Grid container spacing={2}>
              {tscaform.nbciusinvoiceTCSADetails.map(
                (detail, index, details) => (
                  <React.Fragment key={detail.id}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.chemicalName}>
                            {detail.chemicalName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.services}>
                            {detail.services}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.regNum}>
                            {detail.regNum}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {index + 1 < details.length && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default TscaFormViewContent
