import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../lookups/USInvoiceLookupsContext'
import { Divider, Grid, Typography } from '@material-ui/core'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CngField from '../../../../components/cngcomponents/CngField'
import _ from 'lodash'
import moment from 'moment'

function USAPHISFormViewContent(props) {
  const { usAPHISForm } = props

  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portOfEmbarkation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.PORT_OF_EMBARKATION
    )
    const countryOfHealthCare = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.COUNTRY_OF_HEALTH_CARE
    )
    const shippingDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.SHIPPING_DATE
    )
    const arrivalDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.ARRIVAL_DATE
    )
    const usaphisName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_NAME
    )
    const usaphisAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_ADDRESS
    )
    const usaphisCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_CITY
    )
    const usaphisStateCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_STATE_CODE
    )
    const usaphisCountryCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_COUNTRY_CODE
    )
    const usaphisPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_POSTAL_CODE
    )
    const usaphisTelNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_TEL_NO
    )
    const deliveryInUS = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.DELIVERY_IN_U_S
    )
    const importPermitNumbers = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.IMPORT_PERMIT_NUMBERS
    )
    const usaphisRemarks = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_REMARKS
    )
    const usaphisPrintName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_PRINT_NAME
    )
    const usaphisTitle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIS_TITLE
    )
    const vetDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.VET_DATE
    )
    const usaphiportOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.UsAPHISForm.USAPHIPORT_OF_ENTRY
    )
    const usaphisAnimal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.TITLE
    )
    const commonName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.COMMON_NAME
    )
    const animalNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.ANIMAL_NUMBER
    )
    const purposeOfImportation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.PURPOSE_OF_IMPORTATION
    )
    const sex = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.SEX
    )

    return {
      portOfEmbarkation,
      countryOfHealthCare,
      shippingDate,
      arrivalDate,
      usaphisName,
      usaphisAddress,
      usaphisCity,
      usaphisStateCode,
      usaphisCountryCode,
      usaphisPostalCode,
      usaphisTelNo,
      deliveryInUS,
      importPermitNumbers,
      usaphisRemarks,
      usaphisPrintName,
      usaphisTitle,
      vetDate,
      usaphiportOfEntry,
      usaphisAnimal,
      commonName,
      animalNumber,
      purposeOfImportation,
      sex,
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CngField label={translatedTextsObject.usaphisTitle}>
              {getLookupValue('usaphisTitle', usAPHISForm.usaphisTitle)}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <CngField label={translatedTextsObject.usaphisName}>
              {usAPHISForm.usaphisName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CngField label={translatedTextsObject.vetDate}>
              {usAPHISForm.vetDate &&
                moment(usAPHISForm.vetDate).format('D MMM YYYY')}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.portOfEmbarkation}>
              {usAPHISForm.portOfEmbarkation}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.countryOfHealthCare}>
              {getLookupValue('country', usAPHISForm.countryOfHealthCare)}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CngField label={translatedTextsObject.shippingDate}>
              {usAPHISForm.shippingDate &&
                moment(usAPHISForm.shippingDate).format('D MMM YYYY')}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CngField label={translatedTextsObject.arrivalDate}>
              {usAPHISForm.arrivalDate &&
                moment(usAPHISForm.arrivalDate).format('D MMM YYYY')}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CngField label={translatedTextsObject.usaphiportOfEntry}>
              {getLookupValue('usaphiportOfEntry', usAPHISForm.usaphiportOfEntry)}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs='auto'>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              Name and mailing address
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.usaphisPrintName}>
              {usAPHISForm.usaphisPrintName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CngField label={translatedTextsObject.usaphisAddress}>
              {usAPHISForm.usaphisAddress}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisCity}>
              {usAPHISForm.usaphisCity}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisCountryCode}>
              {getLookupValue('country', usAPHISForm.usaphisCountryCode)}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisStateCode}>
              {usAPHISForm.usaphisStateCode}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisPostalCode}>
              {usAPHISForm.usaphisPostalCode}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisTelNo}>
              {usAPHISForm.usaphisTelNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.deliveryInUS}>
              {usAPHISForm.deliveryInUS}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.importPermitNumbers}>
              {usAPHISForm.importPermitNumbers}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.usaphisRemarks}>
              {usAPHISForm.usaphisRemarks}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.usaphisAnimal}>
          {!_.isEmpty(usAPHISForm.nbciusinvoiceUSAPHISAnimalFormDTO) ? (
            <Grid container spacing={2}>
              {usAPHISForm.nbciusinvoiceUSAPHISAnimalFormDTO.map(
                (animal, index, animalForm) => (
                  <React.Fragment key={animal.id}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.commonName}>
                            {animal.commonName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.animalNumber}>
                            {animal.animalNumber}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.purposeOfImportation}>
                            {animal.purposeOfImportation}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.sex}>
                            {animal.sex}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {index + 1 < animalForm.length && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default USAPHISFormViewContent
