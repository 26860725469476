import React,{useEffect, useState} from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import makeValidationSchema from './EPA352021FormMakeValidationSchema'
import NaUSForeignPortAutocompleteField from '../../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import Switch from '../../../../components/Switch'
import Alert from '../../../../components/Alert'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormContext,useWatch } from 'react-hook-form'
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na'
import RadioButtonGroup from '../../../../components/aciacehighway/RadioButtonGroup'

const {
  form: {
    field: { CngTextField, CngCheckboxField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  portCode: '',
  entityDate: '',
  entryNo: '',
  engineBuildDate: '',
  onEngine: false,
  other: '',
  engineManufacturer: '',
  vehicleManufacturer: '',
  printedName: '',
  printedDate: '',
  equipmentTypeA: false,
  equipmentTypeB: false,
  equipmentTypeC: false,
  equipmentTypeD: false,
  equipmentTypeE: false,
  equipmentTypeF: false,
  equipmentTypeG: false,
  equipmentTypeH: false,
  equipmentType1: false,
  equipmentType2: false,
  equipmentType3: false,
  equipmentType4: false,
  equipmentType5: false,
  equipmentType6: false,
  equipmentType7: false,
  equipmentType8: false,
  equipmentType9: false,
  equipmentType10: false,
  equipmentType11: false,
  equipmentType12: false,
  equipmentType13: false,
  equipmentType14: false,
  equipmentType15: false,
  equipmentType16: false,
  equipmentType17: false,
  equipmentType18: false,
  equipmentType19: false,
  equipmentType20: false,
  equipmentType21: false,
  equipmentType22: false,
  equipmentType23: false,
  equipmentType24: false,
  equipmentType25: false,
  equipmentType26A: false,
  equipmentType26B: false,
  equipmentType26C: false,
  equipmentType24Power: '',
  equipmentType24Watts: ""
})

const equipmentTypeFields = [
  'equipmentTypeA',
  'equipmentTypeB',
  'equipmentTypeC',
  'equipmentTypeD',
  'equipmentTypeE',
  'equipmentTypeF',
  'equipmentTypeG',
  'equipmentTypeH',
  'equipmentType1',
  'equipmentType2',
  'equipmentType3',
  'equipmentType4',
  'equipmentType5',
  'equipmentType6',
  'equipmentType7',
  'equipmentType8',
  'equipmentType9',
  'equipmentType10',
  'equipmentType11',
  'equipmentType12',
  'equipmentType13',
  'equipmentType14',
  'equipmentType15',
  'equipmentType16',
  'equipmentType17',
  'equipmentType18',
  'equipmentType19',
  'equipmentType20',
  'equipmentType21',
  'equipmentType22',
  'equipmentType23',
  'equipmentType24',
  'equipmentType25',
  'equipmentType26A',
  'equipmentType26B',
  'equipmentType26C',
]

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { getValues, setValue ,watch} = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function getFieldName(field) {
    return typeof index === 'number'
      ? `nbciusinvoiceDetailsEPA352021Form.${index}.${field}`
      : field
  }

  const equipmentType24Watts = watch(getFieldName('equipmentType24Watts'))

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PORT_CODE
    )
    const entityDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENTITY_DATE
    )
    const entryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENTRY_NO
    )
    const engineBuildDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENGINE_BUILD_DATE
    )
    const onEngine = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ON_ENGINE
    )
    const other = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.OTHER
    )
    const engineManufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.ENGINE_MANUFACTURER
    )
    const vehicleManufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.VEHICLE_MANUFACTURER
    )
    const printedName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PRINTED_NAME
    )
    const printedDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.PRINTED_DATE
    )
    const equipmentTypeA = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_A
    )
    const equipmentTypeB = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_B
    )
    const equipmentTypeC = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_C
    )
    const equipmentTypeD = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_D
    )
    const equipmentTypeE = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_E
    )
    const equipmentTypeF = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_F
    )
    const equipmentTypeG = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_G
    )
    const equipmentTypeH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE_H
    )
    const equipmentType1 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE1
    )
    const equipmentType2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE2
    )
    const equipmentType3 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE3
    )
    const equipmentType4 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE4
    )
    const equipmentType5 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE5
    )
    const equipmentType6 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE6
    )
    const equipmentType7 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE7
    )
    const equipmentType8 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE8
    )
    const equipmentType9 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE9
    )
    const equipmentType10 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE10
    )
    const equipmentType11 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE11
    )
    const equipmentType12 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE12
    )
    const equipmentType13 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE13
    )
    const equipmentType14 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE14
    )
    const equipmentType15 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE15
    )
    const equipmentType16 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE16
    )
    const equipmentType17 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE17
    )
    const equipmentType18 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE18
    )
    const equipmentType19 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE19
    )
    const equipmentType20 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE20
    )
    const equipmentType21 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE21
    )
    const equipmentType22 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE22
    )
    const equipmentType23 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE23
    )
    const equipmentType24 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24
    )
    const equipmentType25 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE25
    )
    const equipmentType26A = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_A
    )
    const equipmentType26B = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_B
    )
    const equipmentType26C = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE26_C
    )
    const equipmentType24Power = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24_POWER
    )
    const equipmentType24Watts = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA352021Form.EQUIPMENT_TYPE24_WATTS
    )

    return {
      portCode,
      entityDate,
      entryNo,
      engineBuildDate,
      onEngine,
      other,
      engineManufacturer,
      vehicleManufacturer,
      printedName,
      printedDate,
      equipmentTypeA,
      equipmentTypeB,
      equipmentTypeC,
      equipmentTypeD,
      equipmentTypeE,
      equipmentTypeF,
      equipmentTypeG,
      equipmentTypeH,
      equipmentType1,
      equipmentType2,
      equipmentType3,
      equipmentType4,
      equipmentType5,
      equipmentType6,
      equipmentType7,
      equipmentType8,
      equipmentType9,
      equipmentType10,
      equipmentType11,
      equipmentType12,
      equipmentType13,
      equipmentType14,
      equipmentType15,
      equipmentType16,
      equipmentType17,
      equipmentType18,
      equipmentType19,
      equipmentType20,
      equipmentType21,
      equipmentType22,
      equipmentType23,
      equipmentType24,
      equipmentType25,
      equipmentType26A,
      equipmentType26B,
      equipmentType26C,
      equipmentType24Power,
      equipmentType24Watts
    }
  }

  
  function handleCheckAll(value) {
    equipmentTypeFields.forEach((field) => setValue(getFieldName(field), value))
  }

  const checkedEquipmentTypeFields = equipmentTypeFields.filter((field) =>
    getValues(getFieldName(field))
  )

  const isAllChecked = checkedEquipmentTypeFields.length === equipmentTypeFields.length

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Declaration of Stationary, Nonroad or Heavy-duty Highway Engine,
              Nonroad Vehicle or Equipment, or Stationary Equipment
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.portCode}>
                <NaAceHwyPOEPortAutocompleteField
                  name={getFieldName('portCode')}
                  label={translatedTextsObject.portCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.entityDate}>
                <CngDateField
                  name={getFieldName('entityDate')}
                  label={translatedTextsObject.entityDate}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.entryNo}>
                <CngTextField
                  name={getFieldName('entryNo')}
                  label={translatedTextsObject.entryNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.engineBuildDate}>
                <CngDateField
                  name={getFieldName('engineBuildDate')}
                  label={translatedTextsObject.engineBuildDate}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} shouldHide={shouldHideMap?.engineManufacturer}>
                <CngTextField
                  name={getFieldName('engineManufacturer')}
                  inputProps={{ maxLength: 50 }}
                  label={translatedTextsObject.engineManufacturer}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} shouldHide={shouldHideMap?.vehicleManufacturer}>
                <CngTextField
                  name={getFieldName('vehicleManufacturer')}
                  inputProps={{ maxLength: 50 }}
                  label={translatedTextsObject.vehicleManufacturer}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.onEngine}>
                <Switch
                  name={getFieldName('onEngine')}
                  label={translatedTextsObject.onEngine}
                  disabled={disabled}
                  fullWidth
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.other}>
                <CngTextField
                  name={getFieldName('other')}
                  inputProps={{ maxLength: 15 }}
                  label={translatedTextsObject.other}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='textSecondary'>
              Names, Addresses, and Telephone Numbers of Relevant Parties
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <strong>Certification:</strong>&nbsp;I certify that I have read
              and understand the purpose of this form, the penalties for falsely
              declaring information, for providing misleading information, or
              for concealing a material fact. The information I have provided is
              correct, and all required attachments are appended to this form. I
              authorise EPA Enforcement Officers to conduct inspections or
              testing permitted by the Clean Air Act. I am the owner, importer,
              or agent for the owner or importer.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.printedDate}>
                <CngDateField
                  name={getFieldName('printedDate')}
                  label={translatedTextsObject.printedDate}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.printedName}>
                <CngTextField
                  name={getFieldName('printedName')}
                  inputProps={{ maxLength: 100 }}
                  label={translatedTextsObject.printedName}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Missing 'Importer details', 'Owner details', 'Broker details' */}
      <Grid item xs={12}>
        <CollapsibleSection title='Equipment code details'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllChecked}
                    indeterminate={!isAllChecked && checkedEquipmentTypeFields.length > 0}
                    onChange={(event) => handleCheckAll(event.target.checked)}
                  />
                }
                label='SELECT ALL'
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Identify the appropriate type of highway or nonroad engine,
                    vehicle, or equipment you are importing the following list
                    of products
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeA}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeA')}
                        label={translatedTextsObject.equipmentTypeA}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeB}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeB')}
                        label={translatedTextsObject.equipmentTypeB}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeC}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeC')}
                        label={translatedTextsObject.equipmentTypeC}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeD}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeD')}
                        label={translatedTextsObject.equipmentTypeD}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeE}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeE')}
                        label={translatedTextsObject.equipmentTypeE}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeF}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeF')}
                        label={translatedTextsObject.equipmentTypeF}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeG}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeG')}
                        label={translatedTextsObject.equipmentTypeG}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentTypeH}>
                      <CngCheckboxField
                        name={getFieldName('equipmentTypeH')}
                        label={translatedTextsObject.equipmentTypeH}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Check one of the following Codes to indicate the provison
                    under which you are importing the engine, vehicle, or
                    equipment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType1}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType1')}
                        label={translatedTextsObject.equipmentType1}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Permanent Exemptions for Nonconforming Engines
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType2}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType2')}
                        label={translatedTextsObject.equipmentType2}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType3}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType3')}
                        label={translatedTextsObject.equipmentType3}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType4}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType4')}
                        label={translatedTextsObject.equipmentType4}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType5}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType5')}
                        label={translatedTextsObject.equipmentType5}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType6}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType6')}
                        label={translatedTextsObject.equipmentType6}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType7}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType7')}
                        label={translatedTextsObject.equipmentType7}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType8}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType8')}
                        label={translatedTextsObject.equipmentType8}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType9}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType9')}
                        label={translatedTextsObject.equipmentType9}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Temporary Exemptions for Nonconforming Engines
                  </Typography>
                  <Box mt={1}>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      style={{ fontWeight: 600 }}
                    >
                      The following temporary exemptions apply for importing
                      nonconforming engines. EPA requests bonding with U.S.
                      Customs Service for the full value of the iomported
                      products to make sure you comply with applicable
                      requirements.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType10}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType10')}
                        label={translatedTextsObject.equipmentType10}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType11}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType11')}
                        label={translatedTextsObject.equipmentType11}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType12}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType12')}
                        label={translatedTextsObject.equipmentType12}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType13}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType13')}
                        label={translatedTextsObject.equipmentType13}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType14}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType14')}
                        label={translatedTextsObject.equipmentType14}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType15}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType15')}
                        label={translatedTextsObject.equipmentType15}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType16}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType16')}
                        label={translatedTextsObject.equipmentType16}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Importation of Engines Not Yet Subject to U.S. EPA Emission
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType17}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType17')}
                        label={translatedTextsObject.equipmentType17}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType18}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType18')}
                        label={translatedTextsObject.equipmentType18}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Importation of Engines Excluded from U.S. EPA Emission
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType19}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType19')}
                        label={translatedTextsObject.equipmentType19}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType20}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType20')}
                        label={translatedTextsObject.equipmentType20}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType21}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType21')}
                        label={translatedTextsObject.equipmentType21}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType22}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType22')}
                        label={translatedTextsObject.equipmentType22}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType23}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType23')}
                        label={translatedTextsObject.equipmentType23}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    Exemptions for Specific Engine Categories or Other Special
                    Cases
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType24}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CngCheckboxField
                            name={getFieldName('equipmentType24')}
                            label={translatedTextsObject.equipmentType24}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap?.equipmentType24Watts}>
                                <RadioButtonGroup
                                 name={getFieldName("equipmentType24Watts")}
                                 label={translatedTextsObject.equipmentType24Watts}
                                 onChange={(event) => setValue(getFieldName('equipmentType24Watts'), event.target.value)}
                                  options={[
                                    {
                                      label: (
                                        <Typography variant='body2' >
                                          <Typography
                                            color='textSecondary'
                                            component='span'
                                            style={{ fontSize: 12, lineHeight: 1.2 }}
                                          >
                                            HP(or identity the regulatory power category)&nbsp;
                                          </Typography>
                                          
                                        </Typography>
                                      ),
                                      value: 'HP'
                                    },
                                    {
                                      label: (
                                        <Typography variant='body2'>
                                          <Typography
                                            color='textSecondary'
                                            component='span'
                                            style={{ fontSize: 12, lineHeight: 1.2 }}
                                          >
                                            kW:&nbsp;
                                          </Typography>
                                        </Typography>
                                      ),
                                      value: 'KW'
                                    }
                                  ]}
                                  value={equipmentType24Watts}
                                />
                            </CngGridItem>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType25}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType25')}
                        label={translatedTextsObject.equipmentType25}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 600 }}>
                    26. Engine imported by an Independent Commercial Importer
                    recognized by EPAO. nly for Categories A and D above.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType26A}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType26A')}
                        label={translatedTextsObject.equipmentType26A}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType26B}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType26B')}
                        label={translatedTextsObject.equipmentType26B}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.equipmentType26C}>
                      <CngCheckboxField
                        name={getFieldName('equipmentType26C')}
                        label={translatedTextsObject.equipmentType26C}
                        disabled={disabled}
                        formControlLabelProps={{
                          style: { alignItems: 'flex-start' }
                        }}
                      />
                    </CngGridItem>
                    <Grid item xs={12} sm={6}>
                      <Alert
                        severity='info'
                        icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                      >
                        <strong>NOTE:</strong>&nbsp;Under 26a and 26b, you may
                        import up to 5 highway engines and 5 nonroad engines in
                        a given model year that are certified to standards based
                        on an engines original production year. You may import
                        any number of engines certified to standards based on
                        the year engine is modified. See 40 CFR 85.1503 and
                        89.603.
                      </Alert>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CollapsibleSection>
      </Grid>
    </Grid>
  )
}

const EPA352021FormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default EPA352021FormFormProperties
