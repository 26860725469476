import makeValidationSchema from './EPA35201FormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import NaUSForeignPortAutocompleteField from '../../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import { useFormContext } from 'react-hook-form'
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na'

const {
  form: {
    field: { CngTextField, CngDateField, CngCheckboxField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  portCode: '',
  entryDate: '',
  entryNo: '',
  vinNumber: '',
  manufactureDate: '',
  manufacturer: '',
  model: '',
  modelYear: '',
  expiryDateOfCert: '',
  dateForForm: '',
  nameCompanyPhone: '',
  vehicleTypeB: false,
  vehicleTypeF: false,
  vehicleTypeEE: false,
  vehicleTypeFF: false,
  vehicleTypeM: false,
  vehicleTypeE: false,
  vehicleTypeL: false,
  vehicleTypeU: false,
  vehicleTypeW: false,
  vehicleTypeY: false,
  vehicleTypeG: false,
  vehicleTypeI: false,
  vehicleTypeK: false,
  vehicleTypeN: false,
  vehicleTypeO: false,
  vehicleTypeA: false,
  vehicleTypeC: false,
  vehicleTypeJ: false,
  vehicleTypeZ: false,
  vehicleTypeH: false,
  vehicleTypeQ: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const vehicleTypeFields = [
  'vehicleTypeB',
  'vehicleTypeF',
  'vehicleTypeEE',
  'vehicleTypeFF',
  'vehicleTypeM',
  'vehicleTypeE',
  'vehicleTypeL',
  'vehicleTypeU',
  'vehicleTypeW',
  'vehicleTypeY',
  'vehicleTypeG',
  'vehicleTypeI',
  'vehicleTypeK',
  'vehicleTypeN',
  'vehicleTypeO',
  'vehicleTypeA',
  'vehicleTypeC',
  'vehicleTypeJ',
  'vehicleTypeZ',
  'vehicleTypeH',
  'vehicleTypeQ'
]

function Fields({ disabled, index, shouldHideMap }) {
  const { getValues, setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const portCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.PORT_CODE
    )
    const entryDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.ENTRY_DATE
    )
    const entryNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.ENTRY_NO
    )
    const vinNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VIN_NUMBER
    )
    const manufactureDate = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MANUFACTURE_DATE
    )
    const manufacturer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MANUFACTURER
    )
    const model = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MODEL
    )
    const modelYear = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.MODEL_YEAR
    )
    const expiryDateOfCert = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.EXPIRY_DATE_OF_CERT
    )
    const dateForForm = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.DATE_FOR_FORM
    )
    const nameCompanyPhone = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.NAME_COMPANY_PHONE
    )
    const vehicleTypeB = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_B
    )
    const vehicleTypeF = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_F
    )
    const vehicleTypeEE = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_EE
    )
    const vehicleTypeFF = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_FF
    )
    const vehicleTypeM = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_M
    )
    const vehicleTypeE = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_E
    )
    const vehicleTypeL = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_L
    )
    const vehicleTypeU = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_U
    )
    const vehicleTypeW = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_W
    )
    const vehicleTypeY = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Y
    )
    const vehicleTypeG = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_G
    )
    const vehicleTypeI = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_I
    )
    const vehicleTypeK = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_K
    )
    const vehicleTypeN = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_N
    )
    const vehicleTypeO = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_O
    )
    const vehicleTypeA = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_A
    )
    const vehicleTypeC = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_C
    )
    const vehicleTypeJ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_J
    )
    const vehicleTypeZ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Z
    )
    const vehicleTypeH = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_H
    )
    const vehicleTypeQ = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsEPA35201Form.VEHICLE_TYPE_Q
    )

    return {
      portCode,
      entryDate,
      entryNo,
      vinNumber,
      manufactureDate,
      manufacturer,
      model,
      modelYear,
      expiryDateOfCert,
      dateForForm,
      nameCompanyPhone,
      vehicleTypeB,
      vehicleTypeF,
      vehicleTypeEE,
      vehicleTypeFF,
      vehicleTypeM,
      vehicleTypeE,
      vehicleTypeL,
      vehicleTypeU,
      vehicleTypeW,
      vehicleTypeY,
      vehicleTypeG,
      vehicleTypeI,
      vehicleTypeK,
      vehicleTypeN,
      vehicleTypeO,
      vehicleTypeA,
      vehicleTypeC,
      vehicleTypeJ,
      vehicleTypeZ,
      vehicleTypeH,
      vehicleTypeQ
    }
  }

  function getFieldName(field) {
    return typeof index === 'number'
      ? `nbciusinvoiceDetailsEPA35201Form.${index}.${field}`
      : field
  }

  function handleCheckAll(value) {
    vehicleTypeFields.forEach((field) => setValue(getFieldName(field), value))
  }

  const checkedVehicleTypeFields = vehicleTypeFields.filter((field) =>
    getValues(getFieldName(field))
  )

  const isAllChecked = checkedVehicleTypeFields.length === vehicleTypeFields.length

	return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Description and Declaration of Motor Vehicle or Motor Vehicle
                Engine (Note: Heavy-duty Engines must use form 3520-21)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.portCode}>
                  <NaAceHwyPOEPortAutocompleteField
                    name={getFieldName('portCode')}
                    label={translatedTextsObject.portCode}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.entryDate}>
                  <CngDateField
                    name={getFieldName('entryDate')}
                    label={translatedTextsObject.entryDate}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.entryNo}>
                  <CngTextField
                    name={getFieldName('entryNo')}
                    label={translatedTextsObject.entryNo}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.vinNumber}>
                  <CngTextField
                    name={getFieldName('vinNumber')}
                    inputProps={{ maxLength: 17 }}
                    label={translatedTextsObject.vinNumber}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.manufactureDate}>
                  <CngDateField
                    name={getFieldName('manufactureDate')}
                    label={translatedTextsObject.manufactureDate}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.manufacturer}>
                  <CngTextField
                    name={getFieldName('manufacturer')}
                    inputProps={{ maxLength: 15 }}
                    label={translatedTextsObject.manufacturer}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.model}>
                  <CngTextField
                    name={getFieldName('model')}
                    inputProps={{ maxLength: 15 }}
                    label={translatedTextsObject.model}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.modelYear}>
                  <CngTextField
                    name={getFieldName('modelYear')}
                    inputProps={{ maxLength: 4 }}
                    label={translatedTextsObject.modelYear}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.expiryDateOfCert}>
                  <CngDateField
                    name={getFieldName('expiryDateOfCert')}
                    label={translatedTextsObject.expiryDateOfCert}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Names, Addresses, and Telephone Numbers of Relevant Parties
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2'>
                <strong>Certification:</strong>&nbsp;I certify that I have read
                and understand the purpose of this form, the penalties for
                falsely declaring information, or for providing misleading
                information, or for concealing a material fact. The information
                I have provided is correct, and all required attachments are
                appended to this form. I authorise EPA Enforcement Officers to
                conduct inspections or testing permitted by the Clean Air Act. I
                am the owner, importer, or agent for the owner or importer.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} md={3} shouldHide={shouldHideMap?.dateForForm}>
                  <CngDateField
                    name={getFieldName('dateForForm')}
                    label={translatedTextsObject.dateForForm}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} md={9} shouldHide={shouldHideMap?.nameCompanyPhone}>
                  <CngTextField
                    name={getFieldName('nameCompanyPhone')}
                    inputProps={{ maxLength: 30 }}
                    label={translatedTextsObject.nameCompanyPhone}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Missing 'Storage location details', 'Importer details', 'Owner details' */}
        <Grid item xs={12}>
          <CollapsibleSection title='Vehicle code details'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllChecked}
                      indeterminate={!isAllChecked && checkedVehicleTypeFields.length > 0}
                      onChange={(event) => handleCheckAll(event.target.checked)}
                    />
                  }
                  label='SELECT ALL'
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      U.S. Conforming and "identical vehicle"
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeB}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeB')}
                          label={translatedTextsObject.vehicleTypeB}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeF}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeF')}
                          label={translatedTextsObject.vehicleTypeF}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeEE}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeEE')}
                          label={translatedTextsObject.vehicleTypeEE}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeFF}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeFF')}
                          label={translatedTextsObject.vehicleTypeFF}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      UPA exempted vehicles
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeM}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeM')}
                          label={translatedTextsObject.vehicleTypeM}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeE}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeE')}
                          label={translatedTextsObject.vehicleTypeE}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      Excluded vehicles
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeL}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeL')}
                          label={translatedTextsObject.vehicleTypeL}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeU}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeU')}
                          label={translatedTextsObject.vehicleTypeU}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeW}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeW')}
                          label={translatedTextsObject.vehicleTypeW}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeY}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeY')}
                          label={translatedTextsObject.vehicleTypeY}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      Temporary imports
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeG}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeG')}
                          label={translatedTextsObject.vehicleTypeG}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeI}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeI')}
                          label={translatedTextsObject.vehicleTypeI}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeK}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeK')}
                          label={translatedTextsObject.vehicleTypeK}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeN}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeN')}
                          label={translatedTextsObject.vehicleTypeN}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeO}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeO')}
                          label={translatedTextsObject.vehicleTypeO}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      Independent commercial importer(ICI) imports
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeA}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeA')}
                          label={translatedTextsObject.vehicleTypeA}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeC}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeC')}
                          label={translatedTextsObject.vehicleTypeC}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeJ}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeJ')}
                          label={translatedTextsObject.vehicleTypeJ}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeZ}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeZ')}
                          label={translatedTextsObject.vehicleTypeZ}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>
                      OEM imports
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeH}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeH')}
                          label={translatedTextsObject.vehicleTypeH}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.vehicleTypeQ}>
                        <CngCheckboxField
                          name={getFieldName('vehicleTypeQ')}
                          label={translatedTextsObject.vehicleTypeQ}
                          disabled={disabled}
                          formControlLabelProps={{
                            style: { alignItems: 'flex-start' }
                          }}
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CollapsibleSection>
        </Grid>
      </Grid>
    </>
	)
}

const EPA35201FormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default EPA35201FormFormProperties
