import makeValidationSchema from './FCCFormMakeValidationSchema'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'
import { NaUSPortAutocompleteField } from 'src/components/na'

const {
  form: {
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  deviceModel: '',
  tradeName: '',
  fccId: '',
  equipDesc: '',
  dateOfEntry: '',
  entryNO: '',
  detailsPortOfEntry: '',
  htsNo: '',
  quantityOfItem: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, index, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const deviceModel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DEVICE_MODEL
    )
    const tradeName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.TRADE_NAME
    )
    const fccId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.FCC_ID
    )
    const equipDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.EQUIP_DESC
    )
    const dateOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DATE_OF_ENTRY
    )
    const entryNO = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.ENTRY_N_O
    )
    const detailsPortOfEntry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.DETAILS_PORT_OF_ENTRY
    )
    const htsNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.HTS_NO
    )
    const quantityOfItem = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFCCForm.QUANTITY_OF_ITEM
    )

    return {
      deviceModel,
      tradeName,
      fccId,
      equipDesc,
      dateOfEntry,
      entryNO,
      detailsPortOfEntry,
      htsNo,
      quantityOfItem
    }
  }

  function getFieldName(field) {
    return typeof index === 'number'
      ? `nbciusinvoiceDetailsFCCForm.${index}.${field}`
      : field
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.deviceModel}>
        <CngTextField
          name={getFieldName('deviceModel')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.deviceModel}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.tradeName}>
        <CngTextField
          name={getFieldName('tradeName')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.tradeName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.fccId}>
        <CngTextField
          name={getFieldName('fccId')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.fccId}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.dateOfEntry}>
        <CngDateField
          name={getFieldName('dateOfEntry')}
          label={translatedTextsObject.dateOfEntry}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.entryNO}>
        <CngTextField
          name={getFieldName('entryNO')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.entryNO}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.detailsPortOfEntry}>
        <NaUSPortAutocompleteField
          name={getFieldName('detailsPortOfEntry')}
          label={translatedTextsObject.detailsPortOfEntry}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.htsNo}>
        <CngTextField
          name={getFieldName('htsNo')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.htsNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.quantityOfItem}>
        <CngTextField
          name={getFieldName('quantityOfItem')}
          inputProps={{ maxLength: 6 }}
          label={translatedTextsObject.quantityOfItem}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.equipDesc}>
        <CngTextField
          name={getFieldName('equipDesc')}
          inputProps={{ maxLength: 15 }}
          label={translatedTextsObject.equipDesc}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const FCCFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FCCFormFormProperties
