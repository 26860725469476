import makeValidationSchema from './TscaDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  chemicalName: '',
  services: '',
  regNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, watch } = useFormContext()

  function makeTranslatedTextsObject() {
    const chemicalName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.CHEMICAL_NAME
    )
    const services = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.SERVICES
    )
    const regNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceTCSADetails.REG_NUMBER
    )

    return { chemicalName, services, regNumber }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.chemicalName}>
        <CngTextField
          name='chemicalName'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.chemicalName}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue("chemicalName", e.target.value, { shouldValidate: true })
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.services}>
        <CngTextField
          name='services'
          inputProps={{ maxLength: 10 }}
          label={translatedTextsObject.services}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue("services", e.target.value, { shouldValidate: true })
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.regNumber}>
        <CngTextField
          name='regNumber'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.regNumber}
          disabled={disabled}
          size='small'
          onChange={(e) => {
            setValue("regNumber", e.target.value, { shouldValidate: true })
          }}
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceTCSADetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceTCSADetailsFormProperties
