import React, { useState } from 'react'
import { components, constants } from 'cng-web-lib'
import { Card, Divider, Grid, Typography } from '@material-ui/core'
import CngSection from '../../../../components/cngcomponents/CngSection'
import PartyInfoCollapsibleSection from './PartyInfoCollapsibleSection'
import { useFormContext, useWatch } from 'react-hook-form'
import { InputAdornment, IconButton, Box, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaNbciPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import _ from 'lodash'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import PartyMasterApiUrls from 'src/apiUrls/NBCIUSCAPartyMasterApiUrls'
import { NaStateCodeAutoCompleteField } from '../../../../components/na'

const {
  form: {
    field: { CngTextField,
      CngCheckboxField,
      CngCountryAutocompleteField }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL, LIKE }
} = constants

function PartyInformationSection(props) {
  const { disabled, shouldHideMap, translatedTextsObject } = props
  const { setValue, reset, trigger, getValues } = useFormContext()
  const [templateDialog, setTemplateDialog] = useState(false)
  const { watch } = useFormContext()
  const consPartysaveFlag = watch('consPartysaveFlag');
  const consPartyCountry = watch('consPartyCountry')
  const consPartyState = watch('consPartyState')


  const columns = [
    {
      field: 'inputPartyID',
      title: translatedTextsObject.consPartyId
    },
    {
      field: 'partyName',
      title: translatedTextsObject.consPartyName,
    },
    {
      title: translatedTextsObject.consPartyAddress,
      render: data => [data.address1, data.address2, data.address3].join(' ')
    }
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.consPartyId,
      value: 'inputPartyID'
    },
    {
      label: translatedTextsObject.consPartyName,
      value: 'partyName'
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        partyName,
        address1,
        nameDBA,
        scac,
        cityName,
        stateCode,
        postalCode,
        countryCode,
        faxNo,
        contactPerson,
        telNo,
        email
      } = template


      setValue('consPartyName', partyName || '', { shouldDirty: true })
      setValue('consPdba', nameDBA || '', { shouldDirty: true })
      setValue('partyScacCode', scac || '', { shouldDirty: true })
      setValue('consPartyAddress', address1 || '', { shouldDirty: true })
      setValue('consPartyCity', cityName || '', { shouldDirty: true })
      setValue('consPartyState', stateCode || '', { shouldDirty: true })
      setValue('consPartyCountry', countryCode || '', { shouldDirty: true })
      setValue('consPartyPostalCode', postalCode || '', { shouldDirty: true })
      setValue('consPartyLocalContactName', contactPerson || '', { shouldDirty: true })
      setValue('consPartyTelephoneNumber', telNo || '', { shouldDirty: true })
      setValue('consPartyFax', faxNo || '', { shouldDirty: true })
      setValue('consPartyEmail', email || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  return (
    <CngSection title='Party Information'>
      <Card variant='outlined'>
        <PartyInfoCollapsibleSection title={translatedTextsObject.growerLabel}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.growerPartyName}>
              <CngTextField
                name='growerPartyName'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.growerPartyName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.growerMID}>
              <CngTextField
                name='growerMID'
                inputProps={{ maxLength: 20 }}
                label={translatedTextsObject.growerMID}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.growerFDARegNumber}>
              <CngTextField
                name='growerFDARegNumber'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.growerFDARegNumber}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </PartyInfoCollapsibleSection>
        <Divider />
        <PartyInfoCollapsibleSection title={translatedTextsObject.manufacturingLabel}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.manufacturingPartyName}>
              <CngTextField
                name='manufacturingPartyName'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.manufacturingPartyName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.manufacturingMID}>
              <CngTextField
                name='manufacturingMID'
                inputProps={{ maxLength: 20 }}
                label={translatedTextsObject.manufacturingMID}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.manufacturingFDARegNum}>
              <CngTextField
                name='manufacturingFDARegNum'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.manufacturingFDARegNum}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </PartyInfoCollapsibleSection>
        <Divider />
        <PartyInfoCollapsibleSection title={translatedTextsObject.consolidatorLabel}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.consolidatorPartyName}>
              <CngTextField
                name='consolidatorPartyName'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.consolidatorPartyName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.consolidatorMID}>
              <CngTextField
                name='consolidatorMID'
                inputProps={{ maxLength: 20 }}
                label={translatedTextsObject.consolidatorMID}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.consolidatorFDARegNumber}>
              <CngTextField
                name='consolidatorFDARegNumber'
                inputProps={{ maxLength: 60 }}
                label={translatedTextsObject.consolidatorFDARegNumber}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </PartyInfoCollapsibleSection>
        <Divider />
        <PartyInfoCollapsibleSection title='Consignee'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.consPartysaveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.consPartysaveFlag}
                      </Typography>
                    }
                    name='consPartysaveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('consPartysaveFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue("consPartyId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap?.consPartyId}>
                  <CngTextField
                    required
                    name="consPartyId"
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.consPartyId}
                    disabled={disabled || !consPartysaveFlag}
                    onChange={(e) => {
                      setValue("consPartyId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaNbciPartyMasterAutoCompleteField
                    name="partyDropDown"
                    label='Auto-fill using Party Master'
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      },
                      //{ field: 'partyType', operator: IN, value: ['LW', 'EX', 'BT', '41', 'MF', 'ST', 'SO','MP','CS','UC','BR','ZZ','NP','SP','CA','IR','CE','GE','CS'] },
                      { field: 'usorcaMaster', operator: EQUAL, value: 'US' }
                      ]
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consPartyName}>
                  <CngTextField
                    name='consPartyName'
                    label={translatedTextsObject.consPartyName}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyIdentifierNumber}>
                  <CngTextField
                    name='consPartyIdentifierNumber'
                    label={translatedTextsObject.consPartyIdentifierNumber}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPfdaRegNum}>
                  <CngTextField
                    name='consPfdaRegNum'
                    label={translatedTextsObject.consPfdaRegNum}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consPdba}>
                  <CngTextField
                    name='consPdba'
                    label={translatedTextsObject.consPdba}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consPartyAddress}>
                  <CngTextField
                    name='consPartyAddress'
                    label={translatedTextsObject.consPartyAddress}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyCity}>
                  <CngTextField
                    name='consPartyCity'
                    label={translatedTextsObject.consPartyCity}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyCountry}>
                  <CngCountryAutocompleteField
                    name='consPartyCountry'
                    key={consPartyCountry}
                    label={translatedTextsObject.consPartyCountry}
                    disabled={disabled}
                    size='small'
                    lookupProps={{
                      label: (record) => `${record.code};${record.descriptionEn}`
                    }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyState}>
                  <NaStateCodeAutoCompleteField
                    name='consPartyState'
                    label={translatedTextsObject.consPartyState}
                    disabled={disabled}
                    size='small'
                    countryCode={consPartyCountry}
                    key={consPartyCountry + consPartyState}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyPostalCode}>
                  <CngTextField
                    name='consPartyPostalCode'
                    label={translatedTextsObject.consPartyPostalCode}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs='auto'>
                  <Typography variant='body2'>Local Contact Information</Typography>
                </Grid>
                <Grid item xs>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyLocalContactName}>
                  <CngTextField
                    name='consPartyLocalContactName'
                    label={translatedTextsObject.consPartyLocalContactName}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyTelephoneNumber}>
                  <CngTextField
                    name='consPartyTelephoneNumber'
                    label={translatedTextsObject.consPartyTelephoneNumber}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyFax}>
                  <CngTextField
                    name='consPartyFax'
                    label={translatedTextsObject.consPartyFax}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.consPartyEmail}>
                  <CngTextField
                    name='consPartyEmail'
                    label={translatedTextsObject.consPartyEmail}
                    disabled={disabled}
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
          <TemplateSelectDialog
            columns={columns}
            defaultSorts={[{ field: 'partyId', direction: 'ASC' }]}
            fetch={{ url: PartyMasterApiUrls.GET }}
            fetchFilters={[
              { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
              //{ field: 'partyType', operator: IN, value: ['LW', 'EX', 'BT', '41', 'MF', 'ST', 'SO','MP','CS','UC','BR','ZZ','NP','SP','CA','IR','CE','GE','CS'] },
              { field: 'usorcaMaster', operator: EQUAL, value: ['US'] }
            ]}
            onApplyTemplate={handleApplyTemplate}
            onClose={() => setTemplateDialog(false)}
            open={templateDialog}
            search={{ field: 'consigneeInputValue', operator: LIKE }}  //custom field name for BE to custom search
            sortOptions={sortOptions}
            title='Template'
          />
        </PartyInfoCollapsibleSection>
      </Card>
    </CngSection>

  )
}

export default PartyInformationSection
