import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const regexDigitWithDecimal = '^[0-9]{0,13}(.[0-9]{0,2})?$'
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 13 digits and 2 decimal.'
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  return Yup.object({
    totalInvoice: Yup.string().required(requiredMessage)
      .nullable()
      .max(16, 'Max length allowedis 16')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    nbciUSInvoiceShipmentSummary: Yup.array(),
    nbciusinvoiceSummaryReference: Yup.array()
  })
}

export default makeValidationSchema
