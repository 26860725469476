import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { USInvoiceLookupsContext } from '../../lookups/USInvoiceLookupsContext'
import CngField from '../../../../components/cngcomponents/CngField'
import { Grid } from '@material-ui/core'

function BolFormViewContent(props) {
  const { bolForm } = props
  
  const { getLookupValue } = useContext(USInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CONTAINER_NO
    )
    const freightCharges = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.FREIGHT_CHARGES
    )
    const placeOfLadding = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.PLACE_OF_LADDING
    )
    const deliveryInstrctions = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.DELIVERY_INSTRUCTIONS
    )
    const codAmount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.COD_AMOUNT
    )
    const bolCurrency = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CURRENCY
    )
    const declaredValueOfShipment = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.DECLARED_VALUE_OF_SHIPMENT
    )
    const chargeTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CHARGE_TO
    )
    const releaseValue = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.RELEASE_VALUE
    )

    return {
      containerNo,
      freightCharges,
      placeOfLadding,
      deliveryInstrctions,
      codAmount,
      bolCurrency,
      declaredValueOfShipment,
      chargeTo,
      releaseValue
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.containerNo}>
          {bolForm.containerNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.codAmount}>
          {bolForm.codAmount}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.freightCharges}>
          {getLookupValue('freightCharges', bolForm.freightCharges)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.declaredValueOfShipment}>
          {bolForm.declaredValueOfShipment}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.placeOfLadding}>
          {bolForm.placeOfLadding}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.releaseValue}>
          {bolForm.releaseValue}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.bolCurrency}>
          {bolForm.bolCurrency}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.deliveryInstrctions}>
          {bolForm.deliveryInstrctions}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.chargeTo}>
          {bolForm.chargeTo}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default BolFormViewContent
