import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import CngField from '../../../../components/cngcomponents/CngField'
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FdabtaFormViewContent(props) {
  const { fdabtaform } = props

  const theme = useTheme()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const usCustomBroker = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.US_CUSTOM_BROKER
    )
    const otherRefNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.OTHER_REF_NO
    )
    const priorNoticeNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.PRIOR_NOTICE_NO
    )
    const billCustomChargsSh = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_SH
    )
    const billCustomChargsCn = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_CN
    )
    const billCustomChargsOt = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.BILL_CUSTOM_CHARGS_OT
    )
    const vesselName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.VESSEL_NAME
    )
    const dateOfExport = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.DATE_OF_EXPORT
    )
    const flightNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.FLIGHT_NO
    )
    const tripNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.TRIP_NO
    )
    const frieghtIncluded = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.FRIEGHT_INCLUDED
    )
    const exportPermitNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.FDABTAForm.EXPORT_PERMIT_NUMBER
    )

    return {
      usCustomBroker,
      otherRefNo,
      priorNoticeNo,
      billCustomChargsSh,
      billCustomChargsCn,
      billCustomChargsOt,
      vesselName,
      dateOfExport,
      flightNo,
      tripNo,
      frieghtIncluded,
      exportPermitNumber
    }
  }

  function renderBillCustomChargeField(name) {
    function getColor() {
      return fdabtaform[name] === 'Y'
        ? theme.palette.primary.main
        : theme.palette.error.main
    }

    function getIcon() {
      return fdabtaform[name] === 'Y' ? 'check' : 'ban'
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <FontAwesomeIcon
            color={getColor()}
            fixedWidth
            icon={['fal', getIcon()]}
          />
        </Grid>
        <Grid item xs>
          <Typography variant='body2'>
            {translatedTextsObject[name]}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.usCustomBroker}>
              {fdabtaform.usCustomBroker}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.otherRefNo}>
              {fdabtaform.otherRefNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.priorNoticeNo}>
              {fdabtaform.priorNoticeNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.vesselName}>
              {fdabtaform.vesselName}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.dateOfExport}>
              {fdabtaform.dateOfExport &&
                moment(fdabtaform.dateOfExport).format('D MMM YYYY')}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography color='textSecondary'>
                  Bill custom charges to
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {renderBillCustomChargeField('billCustomChargsSh')}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {renderBillCustomChargeField('billCustomChargsCn')}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {renderBillCustomChargeField('billCustomChargsOt')}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs='auto'>
            <Typography color='textSecondary'>
              Exporting carrier information
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.flightNo}>
              {fdabtaform.flightNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.tripNo}>
              {fdabtaform.tripNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <FontAwesomeIcon
                  color={
                    fdabtaform.frieghtIncluded === 'true'
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                  }
                  fixedWidth
                  icon={[
                    'fal',
                    fdabtaform.frieghtIncluded === 'true'
                      ? 'check'
                      : 'ban'
                  ]}
                />
              </Grid>
              <Grid item xs>
                {translatedTextsObject.frieghtIncluded}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngField label={translatedTextsObject.exportPermitNumber}>
              {fdabtaform.exportPermitNumber}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FdabtaFormViewContent
