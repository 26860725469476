import React from 'react'
import { components, constants } from 'cng-web-lib'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import EPA352021FormFormProperties from './EPA352021FormFormProperties'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Grid } from '@material-ui/core'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

const { initialValues, makeValidationSchema } =
  EPA352021FormFormProperties.formikProps

function EPA352021FormSection(props) {
  const { onSetDeclaration, title } = props

  const { watch } = useFormContext()
  const EPA352021Form = watch('nbciusinvoiceDetailsEPA352021Form')

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(EPA352021Form)} title={title}>
      {_.isEmpty(EPA352021Form) ? (
        <CngForm
          formikProps={{
            initialValues,
            makeValidationSchema,
            onSubmit: (data) =>
              onSetDeclaration('nbciusinvoiceDetailsEPA352021Form', [data])
          }}
          formState={FormState.COMPLETED}
          innerForm
          renderBodySection={(labelMap, shouldHideMap) => (
            <EPA352021FormFormProperties.Fields shouldHideMap={shouldHideMap} />
          )}
          renderButtonSection={() => (
            <Grid justify='flex-end' container spacing={2}>
              <Grid item xs='auto'>
                <CngButton size='medium' type='submit'>
                  Add declaration
                </CngButton>
              </Grid>
            </Grid>
          )}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonSection onSetDeclaration={onSetDeclaration} />
          </Grid>
          {EPA352021Form.map((_, index) => (
            <Grid key={index} item xs={12}>
              <EPA352021FormFormProperties.Fields index={index} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ButtonSection onSetDeclaration={onSetDeclaration} />
          </Grid>
        </Grid>
      )}
    </CollapsibleSection>
  )
}

function ButtonSection(props) {
  const { onSetDeclaration } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          size='small'
          onClick={() =>
            onSetDeclaration('nbciusinvoiceDetailsEPA352021Form', [])
          }
        >
          Clear
        </CngButton>
      </Grid>
    </Grid>
  )
}

export default EPA352021FormSection
