import makeValidationSchema from './ReferenceMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  referenceType: '',
  referenceIdentification: '',
  refDescription: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const referenceType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REFERENCE_TYPE
    )
    const referenceIdentification = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REFERENCE_IDENTIFICATION
    )
    const refDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.REF_DESCRIPTION
    )

    return { referenceType, referenceIdentification, refDescription }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.referenceType}>
        <CngCodeMasterAutocompleteField
          name='referenceType'
          label={translatedTextsObject.referenceType}
          disabled={disabled}
          codeType='US_INV_DETAIL_REF_TYPE'
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.referenceIdentification}>
        <CngTextField
          name='referenceIdentification'
          inputProps={{ maxLength: 20 }}
          label={translatedTextsObject.referenceIdentification}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.refDescription}>
        <CngTextField
          name='refDescription'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.refDescription}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceDetailsReferenceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceDetailsReferenceFormProperties
