import makeValidationSchema from './USAPHISAnimalMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  commonName: '',
  animalNumber: '',
  purposeOfImportation: '',
  sex: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const commonName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.COMMON_NAME
    )
    const animalNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.ANIMAL_NUMBER
    )
    const purposeOfImportation = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.PURPOSE_OF_IMPORTATION
    )
    const sex = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceUSAPHISAnimalFormDTO.SEX
    )

    return { commonName, animalNumber, purposeOfImportation, sex }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={2} shouldHide={shouldHideMap?.animalNumber}>
        <CngTextField
          name='animalNumber'
          inputProps={{ maxLength: 8 }}
          label={translatedTextsObject.animalNumber}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={2} shouldHide={shouldHideMap?.sex}>
        <CngTextField
          name='sex'
          inputProps={{ maxLength: 8 }}
          label={translatedTextsObject.sex}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.commonName}>
        <CngTextField
          name='commonName'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.commonName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.purposeOfImportation}>
        <CngTextField
          name='purposeOfImportation'
          inputProps={{ maxLength: 50 }}
          label={translatedTextsObject.purposeOfImportation}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const NBCIUSInvoiceUSAPHISAnimalFormDTOFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default NBCIUSInvoiceUSAPHISAnimalFormDTOFormProperties
