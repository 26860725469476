import React from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import CngField from '../../../../../components/cngcomponents/CngField'
import Grid from '@material-ui/core/Grid'

function FDAContent(props) {
  const { fda, translate } = props

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const productDesc = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.PRODUCT_DESC
    )
    const quantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.QUANTITY
    )
    const modelNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.MODEL_NO
    )
    const brandName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsFDAForm.BRAND_NAME
    )

    return { fda, productDesc, quantity, modelNo, brandName }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.productDesc}>
              {fda.productDesc}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.quantity}>
              {fda.quantity}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.modelNo}>
              {fda.modelNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.brandName}>
              {fda.brandName}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FDAContent
