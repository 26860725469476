import makeValidationSchema from './InvoiceDetailsMakeValidationSchema'
import { components, constants, useTranslation, DataFlattener,DateTimeFormatter } from 'cng-web-lib'
import React, { useMemo , useState,useEffect} from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import { Box, Card, Grid, Typography, Divider,InputAdornment,IconButton,useTheme } from '@material-ui/core'
import { NaStateCodeAutoCompleteField, NaHSCodeAutocompleteField } from '../../../../components/na'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import RadioButtonGroup from '../../../../components/aciacehighway/RadioButtonGroup'
import PartyInformationSection from './PartyInformationSection'
import ReferencesSection from './ReferencesSection'
import ServicesSection from './ServicesSection'
import DeclarationsSection from './DeclarationsSection'
import { useFormContext, useWatch } from 'react-hook-form'
import { NaNbciProductMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import _ from 'lodash'
import TemplateSelectDialog from '../../../../components/aciacehighway/TemplateSelectDialog'
import productMasterApiUrls  from '../../../../apiUrls/UsProductMasterApiUrls'
import Alert from '../../../../components/Alert'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCountryAutocompleteField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngUomAutocompleteField,
    },
  },
  CngGridItem,
} = components

const {
  filter: { EQUAL , IN, LIKE}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partNumber: "",
  productDescription: "",
  fdaProductCode: "",
  countryOfOriginCode: "",
  stateOfOrigin: "",
  quantityInvoiced: "",
  uomQuantity: "",
  htsCode: "",
  unitPrice: "",
  netWeight: "",
  netWeightUom: "",
  grossWeight: "",
  grossWeightUom: "",
  lineItemTotal: "",
  markPackLoad: "",
  noOfPackages: "",
  packageType: "",
  productSize: "",
  admname: "",
  admTelephoneNumber: "",
  naftaCriteria: "",
  dateRangeFrom: "",
  dateRangeTo: "",
  netCost: "",
  isProducer: "",
  lineAmount: "",
  includeProdNafta: 'N',
  notAPrducerOfGoods: "",
  templateIndicator: 'N',
  templateName: '',
  manufacturingPartyName: "",
  manufacturingMID: "",
  manufacturingFDARegNum: "",
  growerPartyName: "",
  growerMID: "",
  growerFDARegNumber: "",
  consolidatorPartyName: "",
  consolidatorMID: "",
  consolidatorFDARegNumber: "",
  consTradeParty:"",
  consPartyId:"",
  consPartyName:"",
  consPartyType:"",
  consPartyIdentifierCode:"",
  consPartyIdentifierNumber:"",
  consPartyAddress:"",
  consPartyCity:"",
  consPartyState:"",
  consPartyCountry:"",
  consPartyPostalCode:"",
  consPartyLocalContactName:"",
  consPartyTelephoneNumber:"",
  consPartyFax:"",
  consPdba:"",
  consPfdaRegNum:"",
  consPartyEmail:"",
  nbciusinvoiceDetailsReference: [],
  nbciusinvoiceDetailsService: [],
  nbciusinvoiceDetailsDOTForm: [],
  nbciusinvoiceDetailsFDAForm: [],
  nbciusinvoiceDetailsFCCForm: [],
  nbciusinvoiceDetailsEPA35201Form: [],
  nbciusinvoiceDetailsEPA352021Form: [],
  saveFlag: false,
  consPartysaveFlag:false,
  productID: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, reset, trigger,getValues} = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { watch } = useFormContext()
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups] = useState(null)

  const nbciusinvoiceDetailsService = useWatch({ name: 'nbciusinvoiceDetailsService' });

  const lineItemTotalWatch = getValues('lineItemTotal')
  const lineitemTotalFloat= parseFloat(lineItemTotalWatch)

  useEffect(() => {
    const calculateTotal = () => {
      let additionSum = 0;
      let subtractionSum = 0;
        nbciusinvoiceDetailsService.forEach((obj) => {
          const amount = parseFloat(obj.amount);
          if (obj.additionDeductionCode=="D240") {
            additionSum += amount;
          } else {
            subtractionSum += amount;
          }
        });

      const calculatedTotal = lineitemTotalFloat + additionSum - subtractionSum;
      console.log('Calculated Total:', calculatedTotal);
      setValue('lineAmount', calculatedTotal, { shouldDirty: true })

    };
    calculateTotal();
  }, [lineitemTotalFloat,nbciusinvoiceDetailsService]); 


  const saveFlag = watch('saveFlag');
  const columns = [
    {
      field: 'productId',
      title: translatedTextsObject.productID
    },
    {
      field: 'manufacturingPartNo',
      title: translatedTextsObject.partNumber   
    },
    {
      field: 'itemDescription',
      title: translatedTextsObject.productDescription,
    },
    {
      field: 'productClassificationCode',
      title: translatedTextsObject.fdaProductCode,
    }

  ]

  const sortOptions = [
    {
      label: translatedTextsObject.productID,
      value: 'productID'
    }
  ]

  const {
    partNumber,
    productDescription,
    fdaProductCode,
    countryOfOriginCode,
    stateOfOrigin,
    quantityInvoiced,
    uomQuantity,
    unitPrice,
    htsCode,
    growerPartyName,
    growerMID,
    growerFDARegNumber,
    manufacturingPartyName,
    manufacturingMID,
    manufacturingFDARegNum,
    consolidatorPartyName,
    consolidatorMID,
    consolidatorFDARegNumber,
    consPartyId,
    consPartyName,
    consPartyIdentifierNumber,
    consPfdaRegNum,
    consPdba,
    consPartyAddress,
    consPartyCity,
    consPartyCountry,
    consPartyState,
    consPartyPostalCode,
    consPartyLocalContactName,
    consPartyTelephoneNumber,
    consPartyFax,
    consPartyEmail,
    netWeight,
    netWeightUom,
    grossWeight,
    grossWeightUom,
    lineItemTotal,
    noOfPackages,
    packageType,
    productSize,
    markPackLoad,
    admname,
    admTelephoneNumber,
    includeProdNafta,
    naftaCriteria,
    dateRangeFrom,
    dateRangeTo,
    netCost,
    isProducer,
    notAPrducerOfGoods,
    nbciusinvoiceDetailsDOTForm,
    nbciusinvoiceDetailsFDAForm,
    nbciusinvoiceDetailsFCCForm,
    nbciusinvoiceDetailsEPA35201Form,
    nbciusinvoiceDetailsEPA352021Form,
    lineAmount
  } = useWatch()

  function handleApplyTemplate(template) {
    if (template) {
      const {
        productId,
        productClassificationCode,
        manufacturingPartNo,
        hsCode,
        tariffNo,
        countryOfOrigin,
        uom,
        unitPrice,
        itemDescription,
        markPackLoading,
        manufacturingPartyName,
        manufacturingMID,
        manufacturingFDARegNum,
        growerPartyName,
        growerMID,
        growerFDARegNumber,
        consolidatorPartyName,
        consolidatorMID,
        consolidatorFDARegNumber,
        naftaCriteria,
        dateRangeFrom,
        dateRangeTo,
        netCost,
        isProducer,
        notAProducerOfGoods,
        includeProduct,

      } = template

    
      setValue('productID', productId, { shouldDirty: true })
      setValue('fdaProductCode', productClassificationCode || '', { shouldDirty: true })
      setValue('partNumber', manufacturingPartNo || '', { shouldDirty: true })
      setValue('htsCode', tariffNo || '', { shouldDirty: true })
      setValue('countryOfOriginCode', countryOfOrigin || '', { shouldDirty: true })
      setValue('uomQuantity', uom || '', { shouldDirty: true })
      setValue('unitPrice', unitPrice || '', { shouldDirty: true })
      setValue('productDescription', itemDescription || '', { shouldDirty: true })
      setValue('markPackLoad', markPackLoading || '', { shouldDirty: true })
      setValue('manufacturingPartyName', manufacturingPartyName||'', { shouldDirty: true })
      setValue('manufacturingMID',manufacturingMID||'', { shouldDirty: true })
      setValue('manufacturingFDARegNum', manufacturingFDARegNum || '', { shouldDirty: true })
      setValue('growerPartyName', growerPartyName || '', { shouldDirty: true })
      setValue('growerMID', growerMID || '', { shouldDirty: true })
      setValue('growerFDARegNumber',growerFDARegNumber || '', { shouldDirty: true })
      setValue('consolidatorPartyName', consolidatorPartyName || '', { shouldDirty: true })
      setValue('consolidatorMID', consolidatorMID || '', { shouldDirty: true })
      setValue('consolidatorFDARegNumber',consolidatorFDARegNumber || '', { shouldDirty: true })
      setValue('naftaCriteria', naftaCriteria || '', { shouldDirty: true })
		const valuedateFrom = dateRangeFrom;
		var partsDateFrom = null;
		if (valuedateFrom !== null) {
			partsDateFrom = valuedateFrom.split('/');
		}
		if (partsDateFrom !== null && partsDateFrom.length === 3) {
			// parts[2] is the year, parts[1] is the month, parts[0] is the day
			const formattedDateRangeFrom = `${partsDateFrom[2]}-${partsDateFrom[1]}-${partsDateFrom[0]}`;
			setValue('dateRangeFrom',
				DateTimeFormatter.toClientDate(formattedDateRangeFrom), { shouldDirty: true })
		} else {
			setValue('dateRangeFrom', '', { shouldDirty: true })
		}
		const value = dateRangeTo;
		var parts = null;
		if (value !== null) {
			parts = value.split('/');
		}
      if (parts !== null && parts.length === 3) {
      // parts[2] is the year, parts[1] is the month, parts[0] is the day
      const formattedDateRangeTo = `${parts[2]}-${parts[1]}-${parts[0]}`;
        setValue( 'dateRangeTo',
         DateTimeFormatter.toClientDate(formattedDateRangeTo),{ shouldDirty: true })
      }  else{
        setValue( 'dateRangeTo', '',{ shouldDirty: true })
      }
      setValue('netCost',netCost || '', { shouldDirty: true })
      setValue('isProducer', isProducer || '', { shouldDirty: true })
      setValue('notAPrducerOfGoods', notAProducerOfGoods || '', { shouldDirty: true })
      setValue('includeProdNafta',includeProduct || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    if (inputValue.startsWith('.')) {
      inputValue = '0' + inputValue;
    }

    setValue('unitPrice', inputValue)
  };

  function makeTranslatedTextsObject() {
    let partNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PART_NUMBER
    )
    let productDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_DESCRIPTION
    )
    let fdaProductCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.FDA_PRODUCT_CODE
    )
    let countryOfOriginCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.COUNTRY_OF_ORIGIN_CODE
    )
    let stateOfOrigin = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.STATE_OF_ORIGIN
    )
    let quantityInvoiced = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.QUANTITY_INVOICED
    )
    let uomQuantity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UOM_QUANTITY
    )
    let htsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.HTS_CODE
    )
    let unitPrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UNIT_PRICE
    )
    let netWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT
    )
    let netWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT_UOM
    )
    let grossWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROSS_WEIGHT_UOM
    )
    let lineItemTotal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.LINE_ITEM_TOTAL
    )
    let markPackLoad = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MARK_PACK_LOAD
    )
    let noOfPackages = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NO_OF_PACKAGES
    )
    let packageType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PACKAGE_TYPE
    )
    let productSize = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_SIZE
    )
    let admname = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.ADMNAME
    )
    let admTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.ADM_TELEPHONE_NUMBER
    )
    let naftaCriteria = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NAFTA_CRITERIA
    )
    let dateRangeFrom = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DATE_RANGE_FROM
    )
    let dateRangeTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DATE_RANGE_TO
    )
    let netCost = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_COST
    )
    let isProducer = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.IS_PRODUCER
    )
    let includeProdNafta = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.INCLUDE_PROD_NAFTA
    )
    let manufacturingLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_LABEL
    )
    let manufacturingPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_PARTY_NAME
    )
    let manufacturingMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_M_I_D
    )
    let manufacturingFDARegNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.MANUFACTURING_F_D_A_REG_NUM
    )
    let growerLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_LABEL
    )
    let growerPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_PARTY_NAME
    )
    let growerMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_M_I_D
    )
    let growerFDARegNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.GROWER_F_D_A_REG_NUMBER
    )
    let consolidatorLabel = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_LABEL
    )
    let consolidatorPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_PARTY_NAME
    )
    let consolidatorMID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_M_I_D
    )
    let consolidatorFDARegNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.CONSOLIDATOR_F_D_A_REG_NUMBER
    )
    let consPartyId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_ID
    )
    let consPartyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_NAME
    )
    let consPartyIdentifierNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_IDENTIFIER_NUMBER
    )
    let consPfdaRegNum = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_FDA_REG_NO
    )
    let consPartyAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_ADDRESS
    )
    let consPartyCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_CITY
    )
    let consPartyState = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_STATE
    )
    let consPartyCountry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_COUNTRY
    )
    let consPartyPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_POSTAL_CODE
    )
    let consPartyLocalContactName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_LOCAL_CONTACT_NAME
    )
    let consPartyTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_TELEPHONE_NUMBER
    )
    let consPartyFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_FAX
    )
    let consPdba = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.DBA
    )
    let consPartyEmail = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PARTY_EMAIL
    )
    let invoiceDetailsReference = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsReference.TITLE
    )
    let invoiceDetailsService = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceDetailsService.TITLE
    )
    let productID = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_ID
    )
    const saveFlag = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SAVE_FLAG
    )
    const consPartysaveFlag = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SAVE_FLAG
    )

    return {
      partNumber,
      productDescription,
      fdaProductCode,
      countryOfOriginCode,
      stateOfOrigin,
      quantityInvoiced,
      uomQuantity,
      htsCode,
      unitPrice,
      netWeight,
      netWeightUom,
      grossWeight,
      grossWeightUom,
      lineItemTotal,
      markPackLoad,
      noOfPackages,
      packageType,
      productSize,
      admname,
      admTelephoneNumber,
      naftaCriteria,
      dateRangeFrom,
      dateRangeTo,
      netCost,
      isProducer,
      includeProdNafta,
      manufacturingLabel,
      manufacturingPartyName,
      manufacturingMID,
      manufacturingFDARegNum,
      growerLabel,
      growerPartyName,
      growerMID,
      growerFDARegNumber,
      consolidatorLabel,
      consolidatorPartyName,
      consolidatorMID,
      consolidatorFDARegNumber,
      consPartyId,
      consPartyName,
      consPartyIdentifierNumber,
      consPartyAddress,
      consPartyCity,
      consPartyState,
      consPartyCountry,
      consPartyPostalCode,
      consPartyLocalContactName,
      consPartyTelephoneNumber,
      consPartyFax,
      consPdba,
      consPfdaRegNum,
      consPartyEmail,
      invoiceDetailsReference,
      invoiceDetailsService,
      productID,
      saveFlag,
      consPartysaveFlag
    }
  }

  function handleChangeIncludeProdNafta(event) {
    setValue('includeProdNafta', event.target.checked ? 'Y' : 'N')
    
    if (!event.target.checked) {
      setValue('naftaCriteria', '')
      setValue('dateRangeFrom', '')
      setValue('dateRangeTo', '')
      setValue('isProducer', 'N')
      setValue('notAPrducerOfGoods', '')
    }
  }

  function handleChangeIsProducer(event) {
    setValue('isProducer', event.target.checked ? 'Y' : 'N')
    
    if (event.target.checked) {
      setValue('notAPrducerOfGoods', '')
    }
  }

  const memoedLineItemInformation = useMemo(
    () => (
      <CngSection
        title='Line item information'
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
            Mandatory fields
          </Typography>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.partNumber}>
                <CngTextField
                  name="partNumber"
                  label={translatedTextsObject.partNumber}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={9} shouldHide={shouldHideMap.productDescription}>
                <CngTextField
                  name="productDescription"
                  label={translatedTextsObject.productDescription}
                  isRequired
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.fdaProductCode}>
                <CngTextField
                  name="fdaProductCode"
                  label={translatedTextsObject.fdaProductCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.countryOfOriginCode}>
                <CngCountryAutocompleteField
                  name="countryOfOriginCode"
                  key={countryOfOriginCode}
                  label={translatedTextsObject.countryOfOriginCode}
                  isRequired
                  disabled={disabled}
                  size='small'
				  lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
				}}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.stateOfOrigin}>
                <NaStateCodeAutoCompleteField
                  name="stateOfOrigin"
                  label={translatedTextsObject.stateOfOrigin}
                  disabled={disabled}
                  size='small'
                  countryCode={countryOfOriginCode}
                  key={countryOfOriginCode + stateOfOrigin}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.quantityInvoiced}>
                <CngTextField
                  name="quantityInvoiced"
                  label={translatedTextsObject.quantityInvoiced}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    if (getValues('unitPrice') > 0 || getValues('quantityInvoiced') > 0) {
                      setValue("lineItemTotal", parseFloat((getValues('unitPrice')*getValues('quantityInvoiced')).toFixed(2)), { shouldValidate: true })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.uomQuantity}>
                <CngUomAutocompleteField
                  name="uomQuantity"
                  key={uomQuantity}
                  label={translatedTextsObject.uomQuantity}
                  isRequired
                  disabled={disabled}
                  size='small'
                  lookupProps={{
                    label: (record) => `${record.code}; ${record.descriptionEn}`,
                    filters: [
                      { field: 'uomType', operator: EQUAL, value: 'UPS' }
                    ]
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.unitPrice}>
                <CngTextField
                  name="unitPrice"
                  label={translatedTextsObject.unitPrice}
                  isRequired
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    handleInputChange(e);
                    if (getValues('unitPrice') > 0 || getValues('quantityInvoiced') > 0) {
                      setValue("lineItemTotal", parseFloat((getValues('unitPrice')*getValues('quantityInvoiced')).toFixed(2)), { shouldValidate: true })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.htsCode}>
                <NaHSCodeAutocompleteField
                  name="htsCode"
                  key={htsCode}
                  label={translatedTextsObject.htsCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6}>
                <Box>
                  <Alert
                    severity='info'
                    icon={
                      <FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />
                    }
                  >
                    <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                      <Typography style={{ fontWeight: 600 }} variant='inherit'>
                        NOTE:&nbsp;
                      </Typography>
                      FDA Product Code  format should be as ( NNAAAAN or NNAAANA or NNAAANN or NNAAAAA or NNA--AN or NNA--NA or NNA--AA or NNA--NN or NNA-AAN or NNA-ANA or NNA-ANN or NNA-AAA or NNAA-AN or NNAA-NA or NNAA-NN or NNAA-AA )
                    </Typography>
                  </Alert>
                </Box>
              </CngGridItem>              
            </Grid>
          </Grid>
        </Grid>
      </CngSection>
    ),
    [
      partNumber,
      productDescription,
      fdaProductCode,
      countryOfOriginCode,
      stateOfOrigin,
      quantityInvoiced,
      uomQuantity,
      unitPrice,
      htsCode
    ]
  )

  const memoedPartyInformationSection = useMemo(
    () => (
      <PartyInformationSection
        disabled={disabled}
        shouldHideMap={shouldHideMap}
        translatedTextsObject={translatedTextsObject}
      />
    ),
    [
      growerPartyName,
      growerMID,
      growerFDARegNumber,
      manufacturingPartyName,
      manufacturingMID,
      manufacturingFDARegNum,
      consolidatorPartyName,
      consolidatorMID,
      consolidatorFDARegNumber,
      consPartyName,
      consPartyIdentifierNumber,
      consPfdaRegNum,
      consPdba,
      consPartyAddress,
      consPartyCity,
      consPartyCountry,
      consPartyState,
      consPartyPostalCode,
      consPartyLocalContactName,
      consPartyTelephoneNumber,
      consPartyFax,
      consPartyEmail,
    ]
  )

  const memoedWeightInformation = useMemo(
    () => (
      <CngSection title='Weight Information'>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.netWeight}>
            <CngTextField
              name="netWeight"
              label={translatedTextsObject.netWeight}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.netWeightUom}>
            <CngCodeMasterAutocompleteField
              name="netWeightUom"
              label={translatedTextsObject.netWeightUom}
              disabled={disabled}
              size='small'
              codeType='US_INV_WEIGHT_UOM'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.grossWeight}>
            <CngTextField
              name="grossWeight"
              label={translatedTextsObject.grossWeight}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.grossWeightUom}>
            <CngCodeMasterAutocompleteField
              name="grossWeightUom"
              label={translatedTextsObject.grossWeightUom}
              disabled={disabled}
              size='small'
              codeType='US_INV_WEIGHT_UOM'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.lineItemTotal}>
            <CngTextField
              name="lineItemTotal"
              label={translatedTextsObject.lineItemTotal}
              key={getValues("lineItemTotal")}
              disabled={true}
              onChange={(e) => {
                if (getValues('unitPrice') > 0 || getValues('quantityInvoiced') > 0) {
                  setValue("lineItemTotal", getValues('unitPrice')*getValues('quantityInvoiced'), { shouldValidate: true })
                }
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.noOfPackages}>
            <CngTextField
              name="noOfPackages"
              label={translatedTextsObject.noOfPackages}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.packageType}>
            <CngTextField
              name="packageType"
              label={translatedTextsObject.packageType}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.productSize}>
            <CngTextField
              name="productSize"
              label={translatedTextsObject.productSize}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap.markPackLoad}>
            <CngTextField
              name="markPackLoad"
              label={translatedTextsObject.markPackLoad}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    ),
    [
      netWeight,
      netWeightUom,
      grossWeight,
      grossWeightUom,
      lineItemTotal,
      noOfPackages,
      packageType,
      productSize,
      markPackLoad
    ]
  )

  const memoedAdministrativeCommunicationContact = useMemo(
    () => (
      <CngSection title='Administrative communication contact'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.admname}>
                <CngTextField
                  name="admname"
                  label={translatedTextsObject.admname}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.admTelephoneNumber}>
                <CngTextField
                  name="admTelephoneNumber"
                  label={translatedTextsObject.admTelephoneNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} md={6} shouldHide={shouldHideMap.includeProdNafta && shouldHideMap.naftaCriteria}>
                <Card variant='outlined'>
                  <Box padding='4px 4px 4px 8px'>
                    <Grid container spacing={2}>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.includeProdNafta}>
                        <CngCheckboxField
                          checked={includeProdNafta === 'Y'}
                          label={translatedTextsObject.includeProdNafta}
                          name='includeProdNafta'
                          onChange={handleChangeIncludeProdNafta}
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.naftaCriteria}>
                        <CngCodeMasterAutocompleteField
                          name="naftaCriteria"
                          key={htsCode}
                          label={translatedTextsObject.naftaCriteria}
                          disabled={includeProdNafta !== 'Y'}
                          codeType='ORIGIN_CRITERION'
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </Box>
                </Card>
              </CngGridItem>
            </Grid>
          </Grid>
          {includeProdNafta === 'Y' && (
            <Grid item xs={12}>
              <CollapsibleSection defaultExpanded={includeProdNafta === 'Y'} title='Include USMCA product'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <CngGridItem xs={12} sm={4} shouldHide={includeProdNafta !=='Y'}>
                        <CngDateField
                          name='dateRangeFrom'
                          label={translatedTextsObject.dateRangeFrom}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={4} shouldHide={includeProdNafta !=='Y'}>
                        <CngDateField
                          name='dateRangeTo'
                          label={translatedTextsObject.dateRangeTo}
                          disabled={disabled}
                          size='small'
                        />
                      </CngGridItem>
                      <CngGridItem xs={12} sm={4} shouldHide={includeProdNafta !=='Y'}>
                        <CngCodeMasterAutocompleteField
                          name="netCost"
                          key={netCost}
                          label={translatedTextsObject.netCost}
                          disabled={disabled}
                          codeType='NBCI_PROD_NET_COST'
                          size='small'
                        />
                      </CngGridItem>
                    </Grid>
                  </Grid>
                  <CngGridItem xs={12}>
                    <Card variant='outlined'>
                      <Box padding={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CngCheckboxField
                              checked={isProducer === 'Y'}
                              label={translatedTextsObject.isProducer}
                              name='isProducer'
                              onChange={handleChangeIsProducer}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RadioButtonGroup
                              disabled={isProducer === 'Y'}
                              itemsPerRow={3}
                              onChange={(event) => setValue('notAPrducerOfGoods', event.target.value)}
                              options={[
                                {
                                  label: (
                                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                                      1. Your knowledge of whether the good qualifies as an originating good.
                                    </Typography>
                                  ),
                                  value: '1'
                                },
                                {
                                  label: (
                                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                                      2. Your reliance on the producer's written representation (other than a Certificate of Origin) that the good qualifies as an originating.
                                    </Typography>
                                  ),
                                  value: '2'
                                },
                                {
                                  label: (
                                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                                      3. A completed and signed Certificate for the good, voluntarily provided to the exporter by the producer.
                                    </Typography>
                                  ),
                                  value: '3'
                                }
                              ]}
                              value={notAPrducerOfGoods}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </CngGridItem>
                </Grid>
              </CollapsibleSection>
            </Grid>
          )}
        </Grid>
      </CngSection>
    ),
    [
      admname,
      admTelephoneNumber,
      includeProdNafta,
      naftaCriteria,
      dateRangeFrom,
      dateRangeTo,
      netCost,
      isProducer,
      notAPrducerOfGoods
    ]
  )

  const memoedDeclarationsSection = useMemo(
    () => <DeclarationsSection />,
    [
      nbciusinvoiceDetailsDOTForm,
      nbciusinvoiceDetailsFDAForm,
      nbciusinvoiceDetailsFCCForm,
      nbciusinvoiceDetailsEPA35201Form,
      nbciusinvoiceDetailsEPA352021Form
    ]
  )

	return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <Box padding={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <Grid container spacing={1}>
              <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.saveFlag}>
                <CngCheckboxField
                  label={
                    <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                      {translatedTextsObject.saveFlag}
                    </Typography>
                  }
                  name='saveFlag'
                  disabled={disabled}
                  onChange={(e) => {
                    setValue('saveFlag', e.target.checked)

                    if (!e.target.checked) {
                      setValue("productID", "")
                    }
                  }}
                  size='small'
                  style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.productID}>
                <CngTextField
                  name="productID"
                  inputProps={{
                    style: { textTransform: 'uppercase' },
                    maxLength: 35
                  }}
                  placeholder={translatedTextsObject.productID}
                  disabled={!saveFlag || disabled}
                  onChange={(e) => {
                    setValue("productID", e.target.value.toUpperCase(), { shouldValidate: true })
                  }}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm='auto'>
                <Divider orientation='vertical' />
              </CngGridItem>
              <CngGridItem xs={12} sm>
                  <NaNbciProductMasterAutoCompleteField
                    name="productDropDown"
                    label="Auto-fill using Product Master"
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      },
                      { field: 'usorcaMaster', operator: EQUAL, value: 'US' }
                    ]
                    }} 
                  />
                </CngGridItem>
            </Grid>
                  {memoedLineItemInformation}
                </Grid>
                <Grid item xs={12}>
                  {memoedPartyInformationSection}
                </Grid>
                <Grid item xs={12}>
                  {memoedWeightInformation}
                </Grid>
                <Grid item xs={12}>
                  {memoedAdministrativeCommunicationContact}
                </Grid>
                <Grid item xs={12}>
                  <CngSection title='Other information'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ReferencesSection title={translatedTextsObject.invoiceDetailsReference} />
                      </Grid>
                      <Grid item xs={12}>
                        <ServicesSection title={translatedTextsObject.invoiceDetailsService} />
                      </Grid>
                    </Grid>
                  </CngSection>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {memoedDeclarationsSection}
        </Grid>
      </Grid>
      <TemplateSelectDialog
    columns={columns}
    defaultSorts={[{ field: 'productId', direction: 'ASC' }]}
    fetch={{ url: productMasterApiUrls.GET }}
    fetchFilters={[
      { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
      { field: 'usorcaMaster', operator: EQUAL, value: ['US'] }
    ]}
    onApplyTemplate={handleApplyTemplate}
    onClose={() => setTemplateDialog(false)}
    open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }} //custom field name for BE to custom search
    sortOptions={sortOptions}
    title='Template'
  />
    </>
	)
}

const InvoiceDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InvoiceDetailsFormProperties
