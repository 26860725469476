import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import { Box, Card, CardHeader } from '@material-ui/core'
import InvoiceDetailsViewContent from '../invoiceDetails/view/InvoiceDetailsViewContent'

const {
  button: { CngButton },
  CngDialog
} = components

function InvoiceDetailsTable(props) {
  const { data } = props

  const [dialog, setDialog] = useState({ open: false, invoiceDetail: null })
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.TITLE
    )
    const partNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PART_NUMBER
    )
    const productDescription = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.PRODUCT_DESCRIPTION
    )
    const htsCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.HTS_CODE
    )
    const quantityInvoiced = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.QUANTITY_INVOICED
    )
    const unitPrice = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.UNIT_PRICE
    )
    const netWeight = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.NET_WEIGHT
    )
    const lineItemTotal = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceDetails.LINE_ITEM_TOTAL
    )

    return {
      invoiceDetails,
      partNumber,
      productDescription,
      htsCode,
      quantityInvoiced,
      unitPrice,
      netWeight,
      lineItemTotal
    }
  }

  const columns = [
    {
      field: 'partNumber',
      sortKey: 'partNumber',
      title: translatedTextsObject.partNumber,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'productDescription',
      sortKey: 'productDescription',
      title: translatedTextsObject.productDescription,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'htsCode',
      sortKey: 'htsCode',
      title: translatedTextsObject.htsCode,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'quantityInvoiced',
      sortKey: 'quantityInvoiced',
      title: translatedTextsObject.quantityInvoiced,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'unitPrice',
      sortKey: 'unitPrice',
      title: translatedTextsObject.unitPrice,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'netWeight',
      sortKey: 'netWeight',
      title: translatedTextsObject.netWeight,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'lineItemTotal',
      sortKey: 'lineItemTotal',
      title: translatedTextsObject.lineItemTotal,
      filterConfig: { type: 'textfield' }
    }
  ]

  return (
    <>
      <Card variant='outlined'>
        <CardHeader
          title={translatedTextsObject.invoiceDetails}
          titleTypographyProps={{ style: { fontWeight: 600 } }}
        />
        <LocalTable
          columns={columns}
          data={data}
          onRowClick={(data) => setDialog({ open: true, invoiceDetail: data })}
        />
      </Card>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <InvoiceDetailsViewContent invoiceDetail={dialog.invoiceDetail} />
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setDialog({ open: false, invoiceDetail: null })}>
            OK
          </CngButton>
        }
        dialogTitle='View line item'
        maxWidth='md'
        onClose={() => setDialog({ open: false, invoiceDetail: null })}
        open={dialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default InvoiceDetailsTable
